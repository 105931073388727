import { connect } from 'react-redux'
import { actions as Actions, constants as Constants } from 'acute-public-uilib'
import Comment from './Comment'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { data = {} } = state
  const { user = {}, token } = data

  return {
    user,
    token
  }
}

const mapDispatchToProps = dispatch => ({
  deleteComment: (commentId) => {
    dispatch(Actions.comments.remove(commentId))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Comment)
