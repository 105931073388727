import React from 'react'

import { Icon } from '@blueprintjs/core'
import { Button } from './style'

const translations = {
  "en": {
    "title": "Feedback",
    "whitelabel": "Powered by",
    "back": "Back",
    "button": "Make a suggestion",
    "search": "Search...",
    "roadmap": "Roadmap",
    "message-1": "Let us know what features would make",
    "message-2": "more useful for you.",
  },
  "fr": {
    "title": "Suggestions",
    "whitelabel": "Optimisé par",
    "back": "Retour",
    "button": "Faire une suggestion",
    "search": "Chercher...",
    "roadmap": "Feuille de route",
    "message-1": "Faites-nous savoir quelles fonctionnalités seraient",
    "message-2": "utiles pour vous.",
  },
  "pt": {
    "title": "Sugestões",
    "whitelabel": "Distribuído por",
    "back": "Voltar",
    "button": "Faça uma sugestão",
    "search": "Buscar...",
    "roadmap": "Roteiro",
    "message-1": "Deixe-nos saber quais recursos tornariam o",
    "message-2": "mais útil para você.",
  }
}

export default class Header extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      activeLink: '/'
    }
  }

  changelink = (link) => {
    const { history, location, organization } = this.props

    return history.push(`/v/${organization.slug || 'getacute'}${link}`)
  } 

  render () {
    const { organization, whiteLabel, baseColor, darkColor, location = {}, 
      cardView = false, roadmap = false, locale } = this.props
    const { activeLink } = this.state
    const { pathname } = location

    let backLabel = translations["en"]["back"]
    let firstMessage = translations["en"]["message-1"]
    let secondMessage = translations["en"]["message-2"]
    let titleLabel = translations["en"]["title"]
    let brandingLabel = translations["en"]["whitelabel"]
    let buttonLabel = translations["en"]["button"]
    let searchLabel = translations["en"]["search"]
    let roadmapLabel = translations["en"]["roadmap"]

    if (translations[locale]) {
      backLabel = translations[locale]["back"]
      firstMessage = translations[locale]["message-1"]
      secondMessage = translations[locale]["message-2"]
      titleLabel = translations[locale]["title"]
      brandingLabel = translations[locale]["whitelabel"]
      buttonLabel = translations[locale]["button"]
      searchLabel = translations[locale]["search"]
      roadmapLabel = translations[locale]["roadmap"]
    }

    let state = 'feedback'
    if (pathname.includes('/roadmap')) {
      state = 'roadmap'
    }

    let style = {}
    if (baseColor || darkColor) {
      style = {
        background: `linear-gradient(-100deg,${darkColor},${baseColor})`
      }
    }

    const secondNavStyle = {
      marginTop: '-30px',
      ...style
    }

    return (
      <>
        <nav class="navbar is-transparent is-acute is-public" style={style} role="navigation" aria-label="main navigation">
        <div class="container" style={{display: 'flex'}}>
        <div class="navbar-brand">
          <a class="navbar-item" href={organization.website} target="_blank">
            <img src={organization.logoUrl || "/images/acute_icon.png"} style={{height: '28', width: 'auto', display: 'none'}} />
          </a>
        </div>

        {organization.publicViewRoadmapActive ? 
          <>
            <a 
              className={state === 'feedback' ? "navbar-item is-link is-active" : "navbar-item is-link"}
              onClick={() => this.changelink('/')}
            >
              <span>{titleLabel}</span>
            </a>

            <a 
              className={state === 'roadmap' ? "navbar-item is-link is-active" : "navbar-item is-link"}
              onClick={() => this.changelink('/roadmap')}
            >
              {roadmapLabel}
            </a>
          </>
        : null }

        {
          !whiteLabel ? <div class="navbar-end" style={{
            justifyContent: 'flex-end',
            marginLeft: 'auto'
          }}>
            <div class="navbar-item">
            <a 
              class="button is-primary powered"
              href={`https://getacute.io/acute-link?utm_source=feedback_page&utm_medium=powered&utm_campaign=${organization.name}&company=${organization.name}`}
              target="_new"
            >
              <strong>Powered by Acute</strong>
            </a>
            </div>
          </div> : null
        }
        </div>
      </nav>
      <nav class="navbar is-transparent is-acute is-public is-second" style={secondNavStyle}>
        <div style={{textAlign: 'center'}}>
          {
            cardView ? <h1 class="header title is-spaced">{organization.name} {titleLabel}</h1>
          : roadmap ? <h1 class="header title is-spaced">{organization.name} {roadmapLabel}</h1> 
          : <><h1 class="header title is-spaced">{buttonLabel}</h1>
          <p class="subtitle is-5" style={{color: 'hsla(0,0%,100%,.7)', marginBottom: '1.5em'}}>
          {firstMessage} {organization.name || "our service"} {secondMessage}
          </p></>
          }
        </div>
      </nav>
    </>
    )
  }
}
