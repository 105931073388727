import styled from 'styled-components'
import { Icon } from '@blueprintjs/core'

import theme from '../../shared/theme'
import { margin } from 'polished'

export const Container = styled.div`
  height: 39px;
  ${ props => props.noMargin ? 
    "margin-right: 0" : "margin-right: 1em;"
  }

  color: #ccc;
  fill: #ccc;
  border: 1px solid #e9e9e9;
  padding: 0.1em 0.6em;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid rgba(35,35,35,.2);

  &:hover {
    color: #999;
    fill: #999;
  }

  ${ props => props.active && `
    color: ${props.darkColor || theme.brand.betterDefault} !important;
    border: 1px solid ${props.darkColor || theme.brand.betterDefault};
    fill: ${props.darkColor || theme.brand.betterDefault} !important;
  ` }

  > * {
    display: block;
  }
`

export const UpIcon = styled(Icon)`
  height: 16px;
`