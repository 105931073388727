import React from 'react'
import { Provider } from 'react-redux'
import { routerReducer } from 'react-router-redux'

import { sagas, configureStore } from 'acute-public-uilib'

export default function() {
  class StoreWrapper extends React.PureComponent {
    constructor(props) {
      super(props)

      const config = {
        development: process.env.NODE_ENV === 'production' ? false : true,
        sagas
      }
      const optionalReducers = { router: routerReducer }
      const { store } = configureStore(
        config,
        optionalReducers
      )

      this.store = store
    }

    render () {
      const { context } = this.props
      
      return (
        <Provider store={this.store} context={context}>
          {this.props.children}
        </Provider>
      )
    }
  }

  return StoreWrapper
}
