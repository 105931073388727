import styled from 'styled-components'
import { Icon } from '@blueprintjs/core'

import theme from '../../shared/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 32px;
  margin-top: 10px;
  margin-bottom: 20px;
`

export const Img = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`

export const AuthorDetails = styled.div`
  margin: 0;
  line-height: 1;
  color: ${theme.text.light};
  display: flex;
  align-items: baseline;
`

export const FullName = styled.p`
  font-weight: 600;
  //margin-right: 10px;
  margin-bottom: 0;
  ${props => props.admin && `
    color: ${theme.brand.default};
  `}
`

export const BodyContainer = styled.div`
  font-size: 12px;
  padding-left: 10px;
`

export const ImgContainer = styled.div`
  display: flex;
  position: relative;
  flex: 0 0 auto;
`

export const Badge = styled(Icon)`
  position: absolute;
  top: 20px;
  left: 18px;
  -webkit-text-fill-color: #fff;
  background: white;
  border-radius: 10px;
  color: #4304cc;
  border: 1px solid #fff;
`

export const ActionsContainer = styled.div`
  display: flex;
  padding-top: 10px;
  font-size: 12px;
`

export const ActionLink = styled.button`
  color: ${theme.actions.light};
  border-width: 0px;
  text-decoration: none;
  padding: 0px;
  outline: none !important;
  margin-right: 10px;
  background: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: rgb(137, 147, 164);
  }
`

export const Dot = styled.span`
  margin-left: 6px; 
  margin-right: 6px; 
  color: rgb(129, 140, 157);
`

export const Tag = styled.p`
  background-color: #4400cc7a;
  color: #fff;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 3px;
`

export const AdminBadge = styled.img`
  position: absolute;
  top: 20px;
  left: 18px;
  width: 15px !important;
  height: 15px !important;
  border: 2px solid white;
  border-radius: 10px;
`