import React from 'react'
import { Icon, Tooltip } from '@blueprintjs/core'
import StarIcon from '../../shared/assets/icons/star.svg'

import { Wrapper, Input, ActionsContainer, Button, SwitchWrapper, Badge, HelpIcon } from './style'

const translations = {
  "en": {
    "commentInput": "Leave a comment...",
    "submit": "Post comment",
    "cancel": "Cancel"
  },
  "fr": {
    "commentInput": "Laissez un commentaire...",
    "submit": "Soumettre",
    "cancel": "Annuler"
  },
  "pt": {
    "commentInput": "Deixe um comentário...",
    "submit": "Postar comentário",
    "cancel": "Cancelar"
  }
}
export default class BulmaCommentInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value || '',
      isOpen: props.isOpen || false,
      isPublic: true,
      allowPrivate: props.allowPrivate || false,
      files: [],
      images: props.images || [],
      deletedImages: [],
    }
  }

  onChange = (e) => {
    const { value } = e.target

    this.setState(() => ({ value }))
  }

  onClick = () => {
    const { value, isPublic, files } = this.state
    const { onSubmit } = this.props

    if (value.trim().length > 0) {
      this.setState({
        value: '',
        isOpen: false,
        files: []
      })

      let formData = new FormData()
      files.map((file, index) => {
        formData.append('files[]', file)
      })
      formData.append('body', value)
      onSubmit(formData)
    } else {
      this.input.focus()
    }
  }

  open = () => {
    this.setState({ isOpen: true })
  }

  close = () => {
    const { onCancel } = this.props

    this.setState({ 
      isOpen: false,
      isPublic: true,
      value: '',
      files: []
    })
    if (onCancel) onCancel()
  }

  placeholder = () => {
    const { isOpen } = this.state

    return (
      isOpen ? '' : 'Leave a comment'
    )
  }

  handlePublicChange = () => {
    const { isPublic } = this.state

    this.setState({ isPublic: !isPublic })
  }

  rows = () => {
    const { isOpen } = this.state

    return (
      isOpen ? 3 : 1
    )
  }

  tooltipContent = () => {
    const { isPublic } = this.state

    return (
      "Comment is visible only to you and your team members"
    )
  }

  onImgUpload = (e) => {
    const { updateLogo } = this.props
    let { formData } = this.state 
    let error = false
    const file = e.target.files[0]

    const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']

    if (!file) return

    if (types.every(type => file.type !== type)) {
      console.log('Wrong file type. Expected .png, .jpeg or .jpg')
      error = true
      this.setState({error: 'Wrong file type. Expected .png, .jpeg or .jpg'})
    }

    if (!error) {
      this.setState({
        files: [...this.state.files, file],
      })
    }
  }

  removeImg = (file) => {
    let { files } = this.state

    const index = files.indexOf(file)
    files.splice(index, 1)

    this.setState({
      files: [...files]
    })
  }

  render () {
    const { large = false, avatar, admin, edit, darkColor, locale } = this.props
    const { value, isOpen, isPublic, allowPrivate, files, images } = this.state

    let commenInputLabel = translations["en"]["commentInput"]
    let submitLabel = translations["en"]["submit"]
    let cancelLabel = translations["en"]["cancel"]


    if (translations[locale]) {
      commenInputLabel = translations[locale]["commentInput"]
      submitLabel = translations[locale]["submit"]
      cancelLabel = translations[locale]["cancel"]
    }

    return (
      <article class="media" style={{width: '100%'}}>
        { avatar &&
          <figure class="media-left">
            <p class="image is-32x32">
              <img class="is-rounded" src={avatar} />
              {admin &&
                <Badge
                  src={StarIcon}
                  alt=""
                />
              }
            </p>
          </figure>
        }
        <div class="media-content">
          <div class="field">
            <p class="control">
              {
                isOpen ? <textarea 
                  class="textarea is-focused" 
                  placeholder={commenInputLabel}
                  value={value}
                  onChange={this.onChange}
                  autoFocus
                  ref={(input) => this.input = input} 
                />
              : <input 
                  className={large ? "input is-medium" : "input" }
                  type="text" 
                  placeholder={commenInputLabel}
                  onClick={this.open}
                />
              }
            </p>
          </div>

          { isOpen && 
            <div class="field">
               <nav class="level">
                <div class="level-left">
                {images.map(img =>  <div class="level item upload-img-preview" key={img} style={{position: 'relative', marginBottom: 0, height: '105px'}}>
                    <img src={img} className="post-img-preview" />
                      <span 
                        class="icon is-small is-right" 
                        style={{
                          cursor: 'pointer',
                          top: '5px',
                          cursor: 'pointer',
                          position: 'absolute',
                          right: '9px',
                        }} 
                        onClick={() => this.deleteImg(img)}
                      >
                        <Icon icon={'cross'} />
                      </span>
                  </div>
                  )}
                  {files.map((file, index) =>  <div class="level item upload-img-preview" key={index} style={{position: 'relative', marginBottom: 0, height: '105px'}}>
                    <img src={URL.createObjectURL(file)} className="post-img-preview" />
                      <span 
                        class="icon is-small is-right" 
                        style={{
                          cursor: 'pointer',
                          top: '5px',
                          cursor: 'pointer',
                          position: 'absolute',
                          right: '9px',
                        }} 
                        onClick={() => this.removeImg(file)}
                      >
                        <Icon icon={'cross'} />
                      </span>
                  </div>
                  )}
                  <div class="level-item file">
                  {(files.length + images.length) < 2 ? 
                    <label class="file-label">
                    <Tooltip content={"Add an image"}>
                      <div className="img-upload-container-dark">
                          <input
                            class="file-input"
                            type="file" 
                            id='Logoupload'
                            onChange={this.onImgUpload}
                            style={{cursor: 'pointer'}}
                          />

                        <Icon
                          icon='media'
                          iconSize={13}
                          class="file-cta"
                          style={{cursor: 'pointer'}}
                        />
                      </div>
                      </Tooltip>
                      </label>
                  : null }
                  </div>
              </div>
            </nav>
              <nav class="level">
                <div class="level-left" style={{display: 'flex'}}>
                  <div class="level-item">
                    <button 
                      class="button is-mov"
                      onClick={this.onClick}
                      style={{backgroundColor: (darkColor || "#4400cc")}}
                    >
                      { edit ? "Save" : submitLabel }
                    </button>
                  </div>
                  <div class="level-item">
                    <button 
                      class="button is-white" 
                      onClick={this.close}
                    >
                      {cancelLabel}
                    </button>
                  </div>
                </div>
              { allowPrivate &&
                <div class="level-right">
                  <div class="level-item">
                    <SwitchWrapper
                      checked={!isPublic}
                      label={"Private"}
                      onChange={this.handlePublicChange}
                    />
                    <Tooltip content={this.tooltipContent()}>
                      {/* <Icon
                        icon="help"
                        iconSize={10} 
                        style={{ marginLeft: '5px' }}
                      /> */}
                      <HelpIcon style={{marginLeft: '5px' }}>?</HelpIcon>
                    </Tooltip>
                  </div>
                </div>
              }
              </nav>
            </div>
          }
        </div>
      </article>
    )
  }
}