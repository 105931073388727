import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { actions as Actions } from 'acute-public-uilib'
import Header from './Header'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { data = {} } = state
  const { organisation } = data

  return {
    organization: organisation
  }
}

const mapDispatchToProps = dispatch => ({
  logout: () => {
    dispatch(Actions.orgUser.logout())
  },
  submitRequest: ({ title, body, nonPublic, cb = {} }) => {
    dispatch(Actions.card.submit({ title, body, nonPublic, cb, tags: [] }))
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
