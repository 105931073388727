import React from 'react'
import { constants as Constants } from 'acute-public-uilib'

import { renderFormFields } from '../helpers'
import formFields from './formFields'
import './style.css'

export default class ForgotPassword extends React.PureComponent {
  handleClick = () => {
    const { changeModalState } = this.props
    const { modal: { formState = '' } } = Constants

    changeModalState(formState.SIGNUP)
  }

  render () {
    return (
      <div className="aui-forgotPassword__container">
        <div className="aui-forgotPassword__content">
          <p className="aui-forgotPassword__title">Reset password</p>
          <form className="aui-forgotPassword__form">
            { renderFormFields(formFields) }

            <div className="buttons__container">
              <button>Reset Password</button>
            </div>
          </form>
        </div>
        <div className="aui-forgotPassword__footer">
          <span onClick={this.handleClick}>Cancel</span>
        </div>
      </div>
    )
  }
}
