import React from 'react'
// import { Columns, Column } from 'bloomer'
// import Box from '../../../../components/Box'
import { Spinner, Icon, Tooltip } from '@blueprintjs/core'
import SearchInput from '../../components/SearchInput'
import VoteButton from '../../components/VoteButton'
import CommentIcon from '../../shared/assets/icons/comment.svg'
import CustomDomainCard from '../../components/CustomDomainCard'
import CustomDomainFeedbackHeader from '../../components/CustomDomainFeedbackHeader'
import filterCards from '../../utils/filterCards'
import ModalRenderer from '../../components/ModalRenderer'
import InfiniteScroll from 'react-infinite-scroller';
// import TableDropDown from '../../../../components/TableDropDown'
// import Label from '../../../../components/Label'
// import AddFeedbackModal from '../../../../components/AddFeedbackModal'
// import CardsEmptyState from '../../../DashboardView/components/CardsEmptyState'
// // import statusOptions from '../../statusOptions'
// import sortOptions from '../../sortOptions'
// import stateOptions from '../../stateOptions'
// import InfoImg from '../../../../shared/assets/icons/info-circle-solid.svg'
// import { ReactComponent as SortDown } from '../../../../shared/assets/icons/sort-down.svg'
// import { ReactComponent as SortUp } from '../../../../shared/assets/icons/sort-up.svg'
// import { ReactComponent as QuestionIcon } from '../../../../shared/assets/icons/question-circle-solid.svg'
// import sortCards from '../../../../utils/sortCards'

// import { renderRows, renderBulmaRows } from './helpers'
// import { Wrapper, SwitchWrapper } from './style'
import './style.css'

const translations = {
  "en": {
    "button": "Make a suggestion",
    "whitelabel": "Powered by",
    "sort": {
      "Latest": "Latest",
      "Top": "Top",
      "Oldest": "Oldest"
    },
    "search": "Search"
  },
  "fr": {
    "button": "Faire une suggestion",
    "whitelabel": "Optimisé par",
    "sort": {
      "Latest": "Le plus récent",
      "Top": "Top votes",
      "Oldest": "Le plus ancien"
    },
    "search": "Chercher..."
  },
  "pt": {
    "button": "Faça uma sugestão",
    "whitelabel": "Distribuído por",
    "sort": {
      "Latest": "Recentes",
      "Top": "Mais votados",
      "Oldest": "Antigo",
    },
    "search": "Buscar"
  },
}

export default class PublicFeedbackView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      settingsLoaded: false,
      limit: 20,
      skip: 0,
      hasMore: true
    }
  }

  componentWillMount() {
    document.body.classList.add('public-view-page');
  } 

  componentWillReceiveProps(nextProps) {
    const { organization } = nextProps
    const { getWidgetSettings, ssoLoginUser, loadCards } = this.props
    const { limit, skip } = this.state

    if (organization !== this.props.organization) {
      getWidgetSettings({
        cb: (err, res = {}) => {
          const { data = {} } = res
  
          const { modalBaseColor, modalSecondaryColor, modalNonPublic,
            whiteLabel, locale } = data
  
          this.setState({
            baseColor: modalBaseColor,
            darkColor: modalSecondaryColor || modalBaseColor,
            nonPublic: modalNonPublic,
            whiteLabel: whiteLabel,
            locale: locale,
            settingsLoaded: true
          })
        }
      })

      loadCards({limit, skip})

      const storage = localStorage.getItem('act_public_storage')
      
      if (storage) {
        const storageData = JSON.parse(storage) || {}
        const storageUser = storageData.user || {}
  
        if (storageUser.email) {
          ssoLoginUser({ data: storageUser })
        }
      } 
    }

  }

  componentDidMount() {
    const { getOrg, ssoLoginUser } = this.props
    const domain = window.location.host

    getOrg({domain: domain,
      cb: (err, res = {}) => {
        if (res) {
          const { org = {} } = res
          if (org.name) {
            document.title = `${org.name} Feedback`

            document
            .getElementsByTagName('meta')
            .namedItem('description')
            .setAttribute('content', `Give feedback to the ${org.name} team.`)
          }

          if (org.faviconUrl) {
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/ico';
            link.rel = 'icon';
            link.sizes = "16x16 32x32"
            link.href = org.faviconUrl;
            document.getElementsByTagName('head')[0].appendChild(link);
          }

          const storage = localStorage.getItem('act_public_storage')
          
          if (storage) {
            const storageData = JSON.parse(storage) || {}
            const storageUser = storageData.user || {}
      
            if (storageUser.email) {
              ssoLoginUser({ data: storageUser })
            }
          }
        } else {
          this.setState({error: true})
        }
      }
    })
  }

  onSearchChange = (e) => {
    const { value } = e.target

    this.setState(() => ({ searchValue: value }))
  }


  loadMoreFeedback = (page) => {
    
    this.setState({
      isloadingMoreFeedback: true
    })

    const { loadMoreCards } = this.props
    let { limit, skip } = this.state

    skip = skip + limit

    loadMoreCards({skip, limit, 
      cb: (err, res = {}) => {
        const { data = {} } = res

        this.setState({
          skip: skip,
          hasMore: data.length > 0 ? true : false,
          isloadingMoreFeedback: false
        })
        }
      })
  }

  content = () => {
    const {  cards = [], loading = false, organization } = this.props
    const { settingsLoaded, baseColor, darkColor, whiteLabel, searchValue, locale,
      isloadingMoreFeedback, hasMore } = this.state

    let buttonLabel = translations["en"]["button"]
    let brandingLabel = translations["en"]["whitelabel"]
    let searchPlaceholer = translations["en"]["search"]

    if (translations[locale]) {
      buttonLabel = translations[locale]["button"]
      brandingLabel = translations[locale]["whitelabel"]
      searchPlaceholer = translations[locale]["search"]
    }

    const filteredCards = filterCards(cards, searchValue)

    if (this.props.location.pathname.includes("roadmap")) {
      return (
        this.roadmap()
      )
    }
    
    return (
      <>
        <ModalRenderer darkColor={darkColor} />
        <CustomDomainFeedbackHeader 
          baseColor={baseColor} 
          darkColor={darkColor} 
          whiteLabel={whiteLabel}
          onSearch={this.onSearchChange} 
          searchValue={searchValue}
          locale={locale}
        />

        {
          loading ? <div style={{ position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center' }}>
              <Spinner />
            </div>
          :  <div class="container" style={{maxWidth: '730px', marginTop: '1.5rem'}}>
          <div style={{
              maxHeight: '72vh',
              overflow: 'auto',
              marginTop: '1rem',
              paddingRight: '1rem'
            }}
            ref={(ref) => this.scrollParentRef = ref}>
            <InfiniteScroll
            pageStart={0}
            loadMore={isloadingMoreFeedback ? null : this.loadMoreFeedback}
            hasMore={hasMore}
            loader={<Spinner />}
            useWindow={false}
            getScrollParent={() => this.scrollParentRef}
          >
            { filteredCards.map(card => <CustomDomainCard card={card} darkColor={darkColor} />)}
          </InfiniteScroll></div>
          <div class="app-footer">
            <p class="branding">
              <a href={`https://getacute.io/acute-link?utm_source=feedback_page&utm_medium=powered&utm_campaign=${organization.name}&company=${organization.name}`} target="_new">
                {brandingLabel} <span style={{textDecoration: 'underline'}}>Acute</span>
              </a>
            </p>
          </div>
        </div>
        }
      </>
    )
  }

  render() {
    const { cards = [] } = this.props
    const { settingsLoaded } = this.state

    if (settingsLoaded === false) { 
      return (
        <></>
      )
    } else {
      return this.content()
    }
  }
}