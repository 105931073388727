const theme = {
  bg: {
    default: '#f2f5f7'
  },
  text: {
    default: '#505F79',
    light: '#657382',
    dark: '#202832',
    disabled: '#d5dde3',
    betterDefault: '#525353',
    betterDark: '#292929'
  },
  border: {
    default: '#d5dde3',
    light: '#e1e6eb',
    warm: '#ccc'
  },
  shadow: {
    warm: 'rgba(221, 221, 221, 0.5)'
  },
  hover: {
    bg: '#f4f5f7'
  },
  brand: {
    default: '#4400CC',
    light: '#5500ff',
    alt: '#7B16FF',
    warm: '#673ab7',
    betterDefault: '#7645c0'
  },
  actions: {
    light: '#6b778c'
  },
  icons: {
    default: '#969696'
  },
  blue: {
    default: '#4da6ff',
    light: '#4ca6ffad'
  },
  red: '#ff4d70ad',
  error: '#f26245'
}

export default theme;