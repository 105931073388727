import React from 'react'

import { Icon } from '@blueprintjs/core'
import { Button } from './style'

const translations = {
  "en": {
    "message-1": "Let us know how we can improve.",
    "message-2": "Vote on existing posts or make a new suggestion.",
    "title": "Feedback",
    "email": "Email",
    "description": "Describe the feature",
    "submit": "Submit",
    "whitelabel": "Powered by",
    "back": "Back",
    "button": "Make a suggestion",
    "search": "Search...",
    "open": "Open",
    "in-progress": "In progress",
    "completed": "Completed",
    "roadmap": "Roadmap"
  },
  "fr": {
    "message-1": "Faites-nous savoir quelles fonctionnalités seraient",
    "message-2": "utiles pour vous.",
    "title": "Suggestions",
    "email": "Email",
    "description": "Description",
    "submit": "Soumettre",
    "whitelabel": "Optimisé par",
    "back": "Retour",
    "button": "Faire une suggestion",
    "search": "Chercher...",
    "open": "Prévus",
    "in-progress": "En cours",
    "completed": "Termines",
    "roadmap": "Feuille de route"
  },
  "pt": {
    "message-1": "Deixe-nos saber como podemos melhorar.",
    "message-2": "Vote em postagens existentes ou faça uma nova sugestão.",
    "title": "Sugestões",
    "email": "Email",
    "description": "Descrição",
    "submit": "Enviar",
    "whitelabel": "Distribuído por",
    "back": "Voltar",
    "button": "Faça uma sugestão",
    "search": "Buscar...",
    "open": "Sob revisão",
    "in-progress": "Em progresso",
    "completed": "Completo",
    "roadmap": "Roteiro"
  }
}

export default class Header extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      activeLink: '/'
    }
  }

  changelink = (link) => {
    const { history, location, organization } = this.props

    return history.push(`${link}`)
  } 

  render () {
    const { organization, whiteLabel, baseColor, darkColor, location = {}, onSearch, searchValue, locale } = this.props
    const { activeLink } = this.state
    const { pathname } = location

    let backLabel = translations["en"]["back"]
    let firstMessage = translations["en"]["message-1"]
    let secondMessage = translations["en"]["message-2"]
    let titleLabel = translations["en"]["title"]
    let descriptionLabel = translations["en"]["description"]
    let submitLabel = translations["en"]["submit"]
    let brandingLabel = translations["en"]["whitelabel"]
    let buttonLabel = translations["en"]["button"]
    let searchLabel = translations["en"]["search"]
    let openLabel = translations["en"]["open"]
    let inProgressLabel = translations["en"]["in-progress"]
    let completedLabel = translations["en"]["completed"]
    let roadmapLabel = translations["en"]["roadmap"]

    if (translations[locale]) {
      backLabel = translations[locale]["back"]
      firstMessage = translations[locale]["message-1"]
      secondMessage = translations[locale]["message-2"]
      titleLabel = translations[locale]["title"]
      descriptionLabel = translations[locale]["description"]
      submitLabel = translations[locale]["submit"]
      brandingLabel = translations[locale]["whitelabel"]
      buttonLabel = translations[locale]["button"]
      searchLabel = translations[locale]["search"]
      openLabel = translations[locale]["open"]
      inProgressLabel = translations[locale]["in-progress"]
      completedLabel = translations[locale]["completed"]
      roadmapLabel = translations[locale]["roadmap"]
    }

    let state = 'req'
    if (pathname.includes('/in-progress')) {
      state = 'wip'
    } else if (pathname.includes('/completed')) {
      state = 'done'
    }

    let style = {}
    let iconStyle = {}
    let buttonStyle = {}
    if (baseColor || darkColor) {
      style = {
        background: `linear-gradient(-100deg,${darkColor},${baseColor})`
      }
    }

    if (darkColor) {
      buttonStyle = {
        color: darkColor,
        borderColor: darkColor,
        
        "&:hover": {
          boxShadow: `0 0 0 0.5px ${darkColor}`
        },
      }
      iconStyle = {
        fill: darkColor
      }
    }

    const secondNavStyle = {
      marginTop: '-30px',
      ...style
    }

    return (
      <>
        <nav class="navbar is-transparent is-acute is-public" style={style} role="navigation" aria-label="main navigation">
        <div class="container" style={{display: 'flex'}}>
        <div class="navbar-brand">
          <a class="navbar-item" href={organization.website} target="_blank">
            <img src={organization.logoUrl || "/images/acute_icon.png"} style={{height: '28', width: 'auto', display: 'none'}} />
          </a>
        </div>

        {organization.publicViewRoadmapActive ? 
          <>
            <a class="navbar-item is-link is-active" onClick={() => this.changelink('/')}>
              <span>{titleLabel}</span>
            </a>
      
            <a class="navbar-item is-link" onClick={() => this.changelink('/roadmap')}>
              {roadmapLabel}
            </a>
          </>
        : null }

        {
          !whiteLabel ? <div class="navbar-end" style={{justifyContent: 'flex-end',
            marginLeft: 'auto'}}>
            <div class="navbar-item">
            <a 
              class="button is-primary powered"
              href={`https://getacute.io/acute-link?utm_source=feedback_page&utm_medium=powered&utm_campaign=${organization.name}&company=${organization.name}`}
              target="_new"
            >
              <strong>Powered by Acute</strong>
            </a>
            </div>
          </div> : null
        }
        </div>
      </nav>
      <nav class="navbar is-transparent is-acute is-public is-second" style={secondNavStyle}>
        <div style={{textAlign: 'center'}}>
          <h1 class="header title is-spaced">{organization.name} {titleLabel}</h1>
          <p class="subtitle is-5" style={{color: 'hsla(0,0%,100%,.7)', marginBottom: 0}}>
            {firstMessage}
          </p>
          <p class="subtitle is-5" style={{color: 'hsla(0,0%,100%,.7)', marginTop: 0, marginBottom: 0}}>
            {secondMessage}
          </p>
          <ul class="aui-navbar aui-modal-navbar">
            <li className={state === 'req' ? "active": null}>
              <button class="aui-navbar-button" onClick={() => this.changelink('/')}>{openLabel}</button>
            </li>
            <li className={state === 'wip' ? "active": null}>
              <button class="aui-navbar-button" onClick={() => this.changelink('/in-progress')}>{inProgressLabel}</button>
            </li>
            <li className={state === 'done' ? "active": null}>
              <button class="aui-navbar-button" onClick={() => this.changelink('/completed')}>{completedLabel}</button>
            </li>
          </ul>
          <ul class="aui-navbar aui-modal-navbar">
            <div class="aui-searchbar">
              <Icon 
                icon='search'
                iconSize={12}
              />
              <input type="text" placeholder={searchLabel} value={searchValue} onChange={onSearch}/>
            </div>
              <a href="" class="add-link" onClick={() => this.changelink('/new')}>
                <Button darkColor={darkColor}>
                  <svg style={iconStyle} class="bp3-icon aui-write-button__icon" data-icon="plus" width="16" height="16" viewBox="0 0 16 16"><desc>plus</desc><path d="M13 7H9V3c0-.55-.45-1-1-1s-1 .45-1 1v4H3c-.55 0-1 .45-1 1s.45 1 1 1h4v4c0 .55.45 1 1 1s1-.45 1-1V9h4c.55 0 1-.45 1-1s-.45-1-1-1z" fill-rule="evenodd"></path></svg>
                  {buttonLabel}
                </Button>
              </a>
            </ul>
        </div>
      </nav>
    </>
    )
  }
}
