import React from 'react'
import { Icon } from '@blueprintjs/core'

import { Container, UpIcon } from './style'

const VoteButton = ({ active, value, onClick, noMargin, darkColor }) => (
  <Container
    active={active}
    onClick={onClick}
    noMargin={noMargin}
    darkColor={darkColor}
  >
    <UpIcon icon='chevron-up' active={active} darkColor={darkColor}/>
    <span>{value || 0}</span>
  </Container>
)

export default VoteButton