import React from 'react'
import Label from '../Label'

import STATES from '../../shared/CardStates'

const StateLabel = ({ value }) => {
  const state = STATES.find(state => state.value === value)

  return (
    <Label 
      value={state.label} 
      color={state.color} 
    />
  )
}

export default StateLabel