import styled from 'styled-components'
import { Icon } from '@blueprintjs/core'

import theme from '../../shared/theme'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  border: 1px solid #e1e6eb;
  border-radius: 5px;
  background: #fff;
  padding: 1px;
  
  ${props => props.focused && props.border &&
    `border: 1px solid #aaa;`
  }

  ${props => props.noBorder && `
    border: 0;
  `}

  ${props => props.focused && props.noBorder && `
    background-color: #f7f9fa;

    input {
      background-color: #f7f9fa;
    }
  `}

  ${props => props.withBackground && `
    background-color: whitesmoke;
    
    input {
      background-color: whitesmoke;
    }
  `}

  &:hover {
    ${props => props.noBorder && `
      background-color: #f7f9fa;
    `}

    ${props => props.withBackground && `
      background-color: #eeeeee;
    `}

    input {
      ${props => props.noBorder && `
        background-color: #f7f9fa;
      `}
      
      ${props => props.withBackground && `
        background-color: #eeeeee;
      `}
    }
  }
`

export const Input = styled.input`
  width: 100%;
  flex: 1;
  box-shadow: none;
  resize: none;
  border: none;
  padding: ${props => props.large ?
    `12px` : `10px`} 10px;
  outline: none !important;
  ${props => props.large ?
    `font-size: 14px;`
    : `font-size: 12px;`
  }

  &:active, &:focus {
    box-shadow: none;
  }
`

export const SearchIcon = styled(Icon)`
  margin: 0 0 0 10px;  
  color: ${theme.icons.default};
`