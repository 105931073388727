import { connect } from 'react-redux'
import { actions as Actions } from 'acute-public-uilib'
import { withRouter } from 'react-router-dom'

import App from './App'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { data } = state
  const { OrgUser = {}, organization = {}, subscription = {} } = data

  return {
    OrgUser,
    organization,
    subscription
  }
}

const mapDispatchToProps = dispatch => ({
//   registerUser: (data = {}) => {
//     dispatch(Actions.user.register(data))
//   }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
