import React from 'react'
import { connect } from 'react-redux'
import { actions as Actions } from 'acute-public-uilib'
import { withRouter } from 'react-router-dom'

import Acute from './Acute'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { data } = state
  const { organization = {} } = data

  return {
    organization
  }
}

const mapDispatchToProps = dispatch => ({
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Acute))
