import { connect } from 'react-redux'
import { actions as Actions } from 'acute-public-uilib'
import ModalRenderer from './ModalRenderer'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { data = {} } = state
  const { modalState = {} } = data

  return {
    modalState
  }
}

const mapDispatchToProps = dispatch => ({
  hideModal: () => {
    dispatch(Actions.modal.hide())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalRenderer)
