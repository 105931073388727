import React from 'react'

import VoteButton from '../VoteButton'
import CommentIcon from '../../shared/assets/icons/comment.svg'
import StateLabel from '../StateLabel'
import Autolink from '../../utils/Autolink'

export default class Card extends React.Component {
  componentDidMount () {
    const { card, loadAllVotes } = this.props

    loadAllVotes(card.id)
  }

  componentWillReceiveProps(nextProps) {
    const { user = {}, card, loadAllVotes} = this.props

    if (user.id !== nextProps.user.id) {
      loadAllVotes(card.id)
    }
  }

  upvote = () => {
    const { card = {}, submitUpvote, showModal, token } = this.props

    if (token) {
      submitUpvote(card.id)
    } else {
      showModal(submitUpvote.bind(null, card.id))
    }
  }

  downvote = () => {
    const { card = {}, submitDownvote, showModal, token } = this.props

    if (token) {
      submitDownvote(card.id)
    } else {
      showModal(submitDownvote.bind(null, card.id))
    }
  }

  onVoteClick = (e) => {
    const { vote = {} } = this.props
    e.stopPropagation()

    vote.has_voted ? this.downvote() : this.upvote()
  }

  render() {
    const { card = {}, style, darkColor, vote = {}, organization = {} } = this.props
    let body = ''

    if (card.body) {
     body = card.body.length < 200 ? card.body : (card.body.substring(0, 197) + '...')
    }

    return (
      <div class="box">
        <article class="media">
          <div class="media-content">
            <a style={{ color: '#363636', textDecoration: 'none' }} href={`/v/${organization.slug}/feedback/${card.id}`}>
              <div class="content">
                <nav class="level" style={{marginBottom: '1em'}}>
                  <div>
                    <span class="level-item" style={{justifyContent: 'flex-start'}}>
                      <p class="title is-6 is-spaced">
                        {card.title}
                      </p>
                    </span>
                  </div>
                </nav>
                {card.state === 'planned' || card.state === 'ur' ?
                  <StateLabel
                    value={card.state}
                  />
                : ""}
                <p class="subtitle is-6">
                  <Autolink text={body} />
                </p>
              </div>
            </a>
          </div>
          <div class="media-right">
            <VoteButton
              active={vote.has_voted}
              onClick={this.onVoteClick}
              value={vote.votes || card.votes}
              noMargin
              darkColor={darkColor}
            />
            <a style={{ color: '#363636', textDecoration: 'none' }} href={`/v/${organization.slug}/feedback/${card.id}`}>
              <div class="aui-card__view-comments-button">
                <img src={CommentIcon} style={{ width: '16px', height: '16px' }} />
                <span class="aui-card__total-comments">{card.comments}</span>
              </div>
            </a>
          </div>
        </article>
      </div>
    )
  }
}