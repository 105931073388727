import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { actions as Actions } from 'acute-public-uilib'
import Card from './Card'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { data = {} } = state
  const { OrgUser = {} } = data

  return {
    OrgUser
  }
}

const mapDispatchToProps = dispatch => ({
  // submitUpvote: (cardId) => {
  //   dispatch(Actions.cardVotes.submitUpvote(cardId))
  // },

  // submitDownvote: (cardId) => {
  //   dispatch(Actions.cardVotes.submitDownvote(cardId))
  // },

  // loadAllVotes: (cardId) => {
  //   dispatch(Actions.cardVotes.loadAll(cardId))
  // }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Card))
