import React from 'react'
import BulmaComment from '../BulmaComment'

const translations = {
  "en": {
    "empty": "No comments"
  },
  "fr": {
    "empty": "Sans commentaires",
  },
  "pt": {
    "empty": "Sem comentários",
  }
}

export default ({ locale = 'en', comments = [], handleImgClick }) => (
  comments.length === 0 ? <article key={"empty-comments"} class="media">
    <div class="media-content">
      <p>{translations[locale]["empty"]}</p>
    </div>
  </article>
  : comments.map(comment => (
    <BulmaComment locale={locale} data={comment} key={comment.id} handleImgClick={handleImgClick}/>
  ))
)