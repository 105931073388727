import styled from 'styled-components'

import theme from '../../shared/theme'

export const Button = styled.div`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
  min-width: 170px;
  font-weight: 600;
  line-height: 1;
  color: ${props => props.darkColor || "#4400cc"};
  text-align: center;
  background: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.darkColor || "#4400cc"};
  border-image: initial;
  border-radius: 20px;
  padding: 6px 8px;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    boxShadow: 0 0 0 0.5px ${props => props.darkColor || "#4400cc"};
    opacity: 1;
  }
`