import { connect } from 'react-redux'
import { actions as Actions } from 'acute-public-uilib'

import ForgotPassword from './ForgotPassword'

const mapStateToProps = (state = {}, ownProps = {}) => {
  return {}
}

const mapDispatchToProps = dispatch => ({
  changeModalState: (formState) => {
    dispatch(Actions.modal.changeState(formState))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
