import styled from 'styled-components'
import { Switch } from '@blueprintjs/core'

import theme from '../../shared/theme'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const Input = styled.textarea`
  width: 100%;
  flex: 1;
  box-shadow: none;
  resize: none;
  border: 1px solid #e1e6eb;
  border-radius: 3px;
  padding: ${props => props.large ? 
    `12px` : `10px`} 10px;
  outline: none !important;
  ${props => props.large ? 
    `font-size: 14px;` 
  : `font-size: 12px;`
  }

  &:active, &:focus {
    box-shadow: none;
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  padding-top: 10px;
  font-size: 12px;
`

export const Button = styled.button`
  color: ${theme.actions.light};
  border-width: 0px;
  text-decoration: none;
  padding: 0px 16px;
  outline: none !important;
  margin-right: 10px;
  cursor: pointer;
  height: 2.28571em;
  line-height: 2.28571em;
  border-width: 0px;
  border-radius: 3px;
  ${props => props.large &&
    `font-size: 14px;`
  }
  ${props => props.type === 'submit' && `
      background: ${theme.brand.default};
      color: #fff;
      transition: background .2s ease-in;
  `}

  &:hover {
    color: ${props => props.type === 'submit' ?
      `#fff;` : `rgb(80, 95, 121) !important;`
    }
    background: ${props => props.type === 'submit' ?
      '#4400b8' : `rgba(9, 30, 66, 0.08);`
    }

    ${props => props.type === 'submit' &&
      `box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);`
    }
  }
`

export const SwitchWrapper = styled(Switch)`
  font-size: 14px;
  margin-top: auto !important;
  margin-bottom: auto !important;
`

export const Badge = styled.img`
  position: absolute;
  top: 20px;
  left: 18px;
  width: 15px !important;
  height: 15px !important;
  border: 2px solid white;
  border-radius: 10px;
`

export const HelpIcon = styled.div`
  height: 12px;
  width: 12px;
  background-color: #d4dbe0;
  border-radius: 50%;
  color: white;
  font-weight: 900;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`