import styled from 'styled-components'
import { Box, Column } from 'bloomer'
import { Menu, Icon } from '@blueprintjs/core'

import theme from '../../shared/theme'

export const Header = styled.span`
  color: ${theme.text.default};
  font-weight: 500;
  text-transform: uppercase;
`

export const PageHeader = styled.div`
  color: ${theme.text.dark};
  font-size: 1.5em;
  font-weight: 500;
  margin-bottom: 1em;
  margin-top: 5px;
`

export const Description = styled.p`
  color: ${theme.text.dark};
  font-size: 1.25em;
  line-height: 1.7em;
`

export const FlexedCenteredContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1;
`

export const AuthorContainer = styled(FlexedCenteredContainer)`
  margin-top: 2em;
`

export const Author = styled.img`
  margin-right: 10px;
  border-radius: 50%;
  height: 32px;
`

export const Text = styled.p`
  //color: #202832;
  //line-height: 1.7em;
  margin-bottom: 0;
`

export const FullName = styled.p`
  margin-bottom: 0;
  font-weight: 600;
  ${props => props.admin && `
    color: ${theme.brand.default};
  `}
`

export const CardWrapper = styled(Box)`
  color: rgb(64,67,71);
  margin-left: auto;
  margin-right: auto;
  padding: 1.25rem;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  border: 0;
`

export const Wrapper = styled.div`
  min-height: calc(100vh - 71px);
  margin: auto;
  padding: 1rem 2.5rem;
  align-items: center;

  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`

export const BoxContainer = styled.div`
  margin-top: 1rem;
`

export const Container = styled.div`
  padding: 1em 0;
  position: relative;
  display: flex;
`

export const LeftMenu = styled(Menu)`
  background: none !important;
`
export const MenuItem = styled(Menu.Item)`
  ${props => props.isactive &&
    `font-weight: bold;`
  }
`

export const MenuWrapper = styled(Column)`
  padding-right: 80px;
`

export const AuthorDetails = styled.div`
  margin: 0;
  line-height: 1;
  color: ${theme.text.light};
  display: flex;
  flex-direction: column;
`

export const ImgContainer = styled.div`
  display: flex;
  position: relative;
  flex: 0 0 auto;
`

export const Badge = styled(Icon)`
  position: absolute;
  top: 20px;
  left: 18px;
  -webkit-text-fill-color: #fff;
  background: white;
  border-radius: 10px;
  color: #4304cc;
  border: 1px solid #fff;
`
export const GreenBadge = styled.img`
  position: absolute;
  top: 22px;
  left: 20px;
  width: 17px !important;
  height: 17px !important;
  border: 2px solid white;
  border-radius: 10px;
`

export const ActionsContainer = styled.div`
  display: flex;
  font-size: 12px;
  align-self: flex-end;
`

export const VotersContainer = styled.div`
  margin-top: 1.5em;
`

export const StatusContainer = styled.div`
  margin-top: 2em;
`

export const Tag = styled.p`
  background-color: #4400cc7a;
  color: #fff;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 3px;
  display: inline-block;
`


export const Dot = styled.div`
  margin-left: 6px; 
  margin-right: 6px; 
  color: rgb(129, 140, 157);
`