import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { actions as Actions } from 'acute-public-uilib'
import Card from './Card'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { match = {}, card = {}} = ownProps

  const { data } = state
  const { user = {}, token = null, cards = {},
    votes = {}, organisation } = data
  const cardVote = votes[card.id]

  return {
    user,
    token,
    vote: cardVote,
    organization: organisation
  }
}

const mapDispatchToProps = dispatch => ({
  submitUpvote: (cardId) => {
    dispatch(Actions.cardVotes.submitUpvote(cardId))
  },

  submitDownvote: (cardId) => {
    dispatch(Actions.cardVotes.submitDownvote(cardId))
  },

  showModal: (userAction) => {
    dispatch(Actions.modal.show(userAction))
  },

  loadAllVotes: (cardId) => {
    dispatch(Actions.cardVotes.loadAll(cardId))
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Card))
