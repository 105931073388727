import React from 'react'

import VoteButton from '../VoteButton'
import CommentIcon from '../../shared/assets/icons/comment.svg'
import StateLabel from '../StateLabel'
import { ReactComponent as Trash } from '../../shared/assets/icons/trash.svg'
import StarIcon from '../../shared/assets/icons/star.svg'
import timeDifference from '../../utils/timeDifference'

import { FullName, GreenBadge } from './style'

export default class Card extends React.Component {
  render() {
    const { comment = {}, style, darkColor } = this.props
    const { body, author = {} } = comment
    return (
      <div class="box">
        <article class="media">
          <div class="media-content">
              <div class="content">
                <p class="subtitle is-6">{body}</p>
                <article className="media" style={{alignItems: 'center', borderTop: 'none'}}>
                  <figure className="media-left" style={{ marginLeft: 'unset', marginTop: 'unset', marginBottom: 'unset'}}>
                    <p className="image is-36x36">
                      <img className="is-rounded" src={author && author.avatarURL} style={{width: '36px', height: '36px'}} />
                      { author.admin &&
                        <GreenBadge
                          src={StarIcon}
                          alt=""
                        />
                      }
                    </p>
                  </figure>
                  <div className="media-content">
                    <div className="content">
                      <p style={{ marginBottom: '0'}}>
                        <FullName
                          admin={author.admin}
                          style={author.admin ? {color: '#4400CC'} : {color: '#4a4a4a'}}
                        >
                          {author.fullName}
                        </FullName>
                      </p>
                      <small>{comment.createdAt && timeDifference(comment.createdAt)}</small>
                    </div>
                  </div>
                </article>
              </div>
          </div>
        </article>
      </div>
    )
  }
}