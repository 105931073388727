import React from 'react'
import 'bulma/css/bulma.css'
import '@blueprintjs/core/lib/css/blueprint.css'

import Routes from '../Routes'

export default class AcuteUI extends React.Component {
  render () {
    const { context } = this.props

    return (
      <Routes context={context}/>
    )
  }
}
