import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { App } from '../components/Layout'
const qs = require('qs')

const isAuthenticated = (token) => (
  token ? true : false
)

const UnAuthenticatedRoute = ({ component: Component, token, ...rest }) => (
  <Route {...rest} render={(props) => {
    const { history, location, context } = props

    const params = qs.parse(location.search, {
      ignoreQueryPrefix: true
    })

     const { redirect } = params

    return (
    isAuthenticated(token) === false
      ? <App><Component {...props} /></App>
      : redirect ? <Redirect to={redirect} {...props} /> : <Redirect to='/' {...props} />
  )}} />
)

export default UnAuthenticatedRoute