import React from 'react'
import { Route, Switch } from 'react-router-dom'

import UnAuthenticatedRoute from '../../utils/UnAuthenticatedRoute'
import NoLayoutPrivateRoute from '../../utils/NoLayoutPrivateRoute'
import AppRoute from '../../utils/AppRoute'

import PublicFeedbackView from '../../pages/PublicFeedbackView'
import InProgressFeedbackView from '../../pages/InProgressFeedbackView'
import CompletedFeedbackView from '../../pages/CompletedFeedbackView'
import NewFeedbackView from '../../pages/NewFeedbackView'
import FeedbackView from '../../pages/FeedbackView'
import RoadmapPublicView from '../../pages/RoadmapPublicView'

import CustomDomainPublicFeedbackView from '../../pages/CustomDomainPublicFeedbackView'
import CustomDomainInProgressFeedbackView from '../../pages/CustomDomainInProgressFeedbackView'
import CustomDomainCompletedFeedbackView from '../../pages/CustomDomainCompletedFeedbackView'
import CustomDomainNewFeedbackView from '../../pages/CustomDomainNewFeedbackView'
import CustomDomainFeedbackView from '../../pages/CustomDomainFeedbackView'
import CustomDomainRoadmapPublicView from '../../pages/CustomDomainRoadmapPublicView'

export default class Routes extends React.Component {
  render () {
    const { _persist = {}, token = null, context = {}, location } = this.props
    const { rehydrated = false } = _persist

    if (rehydrated === true) {
      return (
        <>
          <Switch>
            <AppRoute exact path='/v/:id' component={PublicFeedbackView} />
            <AppRoute exact path='/v/:id/in-progress' component={InProgressFeedbackView} />
            <AppRoute exact path='/v/:id/completed' component={CompletedFeedbackView} />
            <AppRoute exact path='/v/:id/new' component={NewFeedbackView} />
            <AppRoute exact path='/v/:id/feedback/:feedbackId' component={FeedbackView} />
            <AppRoute exact path='/v/:id/roadmap' component={RoadmapPublicView} />

            <AppRoute exact path='/' component={CustomDomainPublicFeedbackView} />
            <AppRoute exact path='/in-progress' component={CustomDomainInProgressFeedbackView} />
            <AppRoute exact path='/completed' component={CustomDomainCompletedFeedbackView} />
            <AppRoute exact path='/new' component={CustomDomainNewFeedbackView} />
            <AppRoute exact path='/feedback/:feedbackId' component={CustomDomainFeedbackView} />
            <AppRoute exact path='/roadmap' component={CustomDomainRoadmapPublicView} />
          </Switch> 
        </>
      )
    }
    return null
  }
}
