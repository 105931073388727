import React from 'react'
import { constants as Constants } from 'acute-public-uilib'

import { renderFormFields } from '../helpers'
import formFields from './formFields'
import './style.css'

export default class LoginForm extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      error: null
    }
  }

  handleClick = state => e => {
    const { changeModalState } = this.props

    changeModalState(state)
  }

  _handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit = (e) => {
    const { login, hideModal } = this.props
    const { email = '', password = '' } = this.state

    e.preventDefault()

    login({
      email,
      password,
      cb: (err, res = {}) => {
        if (err) {
          this.setState({ error: 'Invalid email or password' })
        } else {
          localStorage.setItem('act_public_storage', JSON.stringify(res))
          hideModal()
        }
      }
    })
  }

  render () {
    const { modal: { formState = '' } } = Constants
    const { darkColor } = this.props
    const { error } = this.state

    return (
      <div className="aui-loginForm__container">
        <div className="aui-loginForm__content">
          <p className="aui-loginForm__title">Login</p>
          <form className="aui-loginForm__form">
            <div className="error__container">{ error }</div>

            { renderFormFields(formFields, this._handleChange) }

            <div className="buttons__container">
              <button 
                style={{background: darkColor}} 
                onClick={this.handleSubmit}
              >
                Log in
              </button>
               { /* <span onClick={this.handleClick(formState.FORGOT_PASSWORD)}>Forgot password?</span> */ }
            </div>
          </form>
        </div>
        <div className="aui-loginForm__footer">
          <span onClick={this.handleClick(formState.SIGNUP)}>Don't have an account? Sign up</span>
        </div>
      </div>
    )
  }
}
