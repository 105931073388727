import React from 'react'
import { Icon, Tooltip } from '@blueprintjs/core'

import { Wrapper, Input, ActionsContainer, Button, SwitchWrapper } from './style'

export default class CommentInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value || '',
      isOpen: props.isOpen || false,
      isPublic: true,
      allowPrivate: props.allowPrivate || false
    }
  }

  onChange = (e) => {
    const { value } = e.target

    this.setState(() => ({ value }))
  }

  onClick = () => {
    const { value, isPublic } = this.state
    const { onSubmit } = this.props

    if (value.trim().length > 0) {
      this.setState({
        value: '',
        isOpen: false
      })
      onSubmit({
        comment: value,
        nonPublic: !isPublic
      })
    } else {
      this.input.focus()
    }
  }

  open = () => {
    this.setState({ isOpen: true })
  }

  close = () => {
    const { onCancel } = this.props

    this.setState({ 
      isOpen: false,
      isPublic: true,
      value: '' 
    })
    if (onCancel) onCancel()
  }

  placeholder = () => {
    const { isOpen } = this.state

    return (
      isOpen ? '' : 'Leave a comment'
    )
  }

  handlePublicChange = () => {
    const { isPublic } = this.state

    this.setState({ isPublic: !isPublic })
  }

  rows = () => {
    const { isOpen } = this.state

    return (
      isOpen ? 3 : 1
    )
  }

  tooltipContent = () => {
    const { isPublic } = this.state

    return (
      isPublic ? "Comment will be seen by everyone"
      : "Comment will be seen only by you and your team"
    )
  }

  render () {
    const { large } = this.props
    const { value, isOpen, isPublic, allowPrivate } = this.state

    return (
      <div>
        <Wrapper>
          <Input
            value={value}
            onChange={this.onChange}
            placeholder={this.placeholder()}
            onClick={this.open}
            rows={this.rows()}
            ref={(input) => this.input = input} 
            large={large}
          />
        </Wrapper>

        { isOpen &&
          <>
            <ActionsContainer>
              <Button
                type="submit"
                onClick={this.onClick}
                large={large}
              >
                Submit
              </Button>
              <Button 
                onClick={this.close}
                large={large}
              >
                Cancel
              </Button>
              { allowPrivate &&
                <>
                  <SwitchWrapper
                    checked={isPublic}
                    label={isPublic ? "Public" : "Private"}
                    onChange={this.handlePublicChange}
                  />
                  <Tooltip  content={this.tooltipContent()}>
                    <Icon 
                      icon="help" 
                      iconSize={10} 
                      style={{marginLeft: '5px'}} 
                      />
                  </Tooltip>
                </>
              }
            </ActionsContainer>
          </>
        }
      </div>
    )
  }
}