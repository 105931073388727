import { connect } from 'react-redux'
import { actions as Actions } from 'acute-public-uilib'
import { withRouter } from 'react-router-dom'

import Routes from './Routes'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { data, _persist = {} } = state
  const { token = null } = data

  return {
    token,
    _persist,
  }
}

const mapDispatchToProps = dispatch => ({
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes))
