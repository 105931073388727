import React from 'react'

import { Wrapper } from './style'

const Label = ({ value, color }) => (
    <Wrapper
      color={color}
    >
      {value}
    </Wrapper>
)

export default Label