import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { actions as Actions } from 'acute-public-uilib'
import { constants as Constants } from 'acute-public-uilib'
import PublicFeedbackView from './PublicFeedbackView'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { data } = state
  const { organisation = {}, user = {}, token = null } = data

  return {
    organization: organisation,
    user,
    token
  }
}

const mapDispatchToProps = dispatch => ({
  getOrg: ({ slug, cb } = {}) => {
    dispatch(Actions.org.getBySlug({ slug, cb }))
  },
  getWidgetSettings: ({ cb } = {}) => {
    dispatch(Actions.widget.getSettings({ cb }))
  },
  submitCard: ({ cb, formData } = {}) => {
    dispatch(Actions.card.submit({ cb, formData }))
  },

  showModal: (userAction) => {
    dispatch(Actions.modal.show(userAction))
  },

  ssoLoginUser: ({ data, cb } = {}) => {
    dispatch(Actions.user.ssoLogin({ data, cb }))
  },

  ssoUser: ({ data, userAction, cb } = {}) => {
    dispatch(Actions.userAction.set(userAction))
    dispatch(Actions.user.sso({ data, cb }))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicFeedbackView))
