import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { actions as Actions } from 'acute-public-uilib'
import { constants as Constants } from 'acute-public-uilib'
import PublicFeedbackView from './PublicFeedbackView'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { match = {} } = ownProps
  const { params = {} } = match
  const { feedbackId } = params

  const { data } = state
  const { organisation = {}, user = {}, token = null, cards = {},
    comments = {}, loading = {} } = data
  const card = cards[feedbackId]
  const cardComments = comments[feedbackId]

  return {
    organization: organisation,
    user,
    token,
    card,
    comments: cardComments,
    loading: loading.isFetching
  }
}

const mapDispatchToProps = dispatch => ({
  getOrg: ({ domain, cb } = {}) => {
    dispatch(Actions.org.getByDomain({ domain, cb }))
  },

  getWidgetSettings: ({ cb } = {}) => {
    dispatch(Actions.widget.getSettings({ cb }))
  },

  getCard: (cardId) => {
    dispatch(Actions.card.getById(cardId))
  },

  loadComments: (cardId) => {
    dispatch(Actions.comments.loadForCard(cardId))
  },

  loadAllVotes: (cardId) => {
    dispatch(Actions.cardVotes.loadAll(cardId));
  },

  submitComment: ({ id, formData, cb } = {}) => {
    dispatch(Actions.comments.submit({ id, formData, cb }))
  },

  showModal: (userAction) => {
    dispatch(Actions.modal.show(userAction))
  },

  deleteCard: ({ id, cb } = {}) => {
    dispatch(Actions.card.remove({ id, cb }))
  },

  ssoLoginUser: ({ data, cb } = {}) => {
    dispatch(Actions.user.ssoLogin({ data, cb }))
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicFeedbackView))
