import React from 'react'
// import { Columns, Column } from 'bloomer'
// import Box from '../../../../components/Box'
import { Spinner, Icon, Tooltip } from '@blueprintjs/core'
import SearchInput from '../../components/SearchInput'
import VoteButton from '../../components/VoteButton'
import CommentIcon from '../../shared/assets/icons/comment.svg'
import FullCard from '../../components/FullCard'
import SmallFeedbackHeader from '../../components/SmallFeedbackHeader'
import ModalRenderer from '../../components/ModalRenderer'
import Comment from '../../components/Comment'
import BulmaCommentInput from '../../components/BulmaCommentInput'
import BulmaCommentList from '../../components/BulmaCommentList'
// import TableDropDown from '../../../../components/TableDropDown'
// import Label from '../../../../components/Label'
// import AddFeedbackModal from '../../../../components/AddFeedbackModal'
// import CardsEmptyState from '../../../DashboardView/components/CardsEmptyState'
// // import statusOptions from '../../statusOptions'
// import sortOptions from '../../sortOptions'
// import stateOptions from '../../stateOptions'
// import InfoImg from '../../../../shared/assets/icons/info-circle-solid.svg'
// import { ReactComponent as SortDown } from '../../../../shared/assets/icons/sort-down.svg'
// import { ReactComponent as SortUp } from '../../../../shared/assets/icons/sort-up.svg'
// import { ReactComponent as QuestionIcon } from '../../../../shared/assets/icons/question-circle-solid.svg'
// import sortCards from '../../../../utils/sortCards'

// import { renderRows, renderBulmaRows } from './helpers'
// import { Wrapper, SwitchWrapper } from './style'
import './style.css'

const translations = {
  "en": {
    "whitelabel": "Powered by",
    "back": "Back",
    "comments": "Comments",
    "commentInput": "Leave a comment...",
    "days-ago": "days ago",
    "months-ago": "months ago"
  },
  "fr": {
    "whitelabel": "Optimisé par",
    "back": "Retour",
    "comments": "Commentaires",
    "commentInput": "Laissez un commentaire...",
    "days-ago": "jours il y a",
    "months-ago": "mois il y a"
  },
  "pt": {
    "whitelabel": "Distribuído por",
    "back": "Voltar",
    "comments": "Comentários",
    "commentInput": "Deixe um comentário...",
    // "days-ago": "Há dias",
    // "months-ago": ""
  },
}


export default class PublicFeedbackView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      settingsLoaded: false,
      title: '',
      body: '',
      imageModalOpen: false
    }
  }

  componentWillMount() {
    document.body.classList.add('public-view-page');
  }
  
  componentDidMount () {
    const { loadComments, loadAllVotes, card, match, 
      getOrg } = this.props
    const { params = {} } = match

    getOrg({slug: params.id, 
      cb: (err, res = {}) => {
        if (res) {
          const { org = {} } = res
          if (org.name) {
            document.title = `${org.name} Feedback`

            document
            .getElementsByTagName('meta')
            .namedItem('description')
            .setAttribute('content', `Give feedback to the ${org.name} team.`)
          }

          if (org.faviconUrl) {
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/ico';
            link.rel = 'icon';
            link.sizes = "16x16 32x32"
            link.href = org.faviconUrl;
            document.getElementsByTagName('head')[0].appendChild(link);
          }
        } else {
          this.setState({error: true})
        }
      }
    }) 
  }

  componentWillReceiveProps(nextProps) {
    const { organization, card } = nextProps
    const { getWidgetSettings, getCard, match,
      loadComments, loadAllVotes, ssoLoginUser, user } = this.props
    const { params = {} } = match

    if (organization !== this.props.organization) {
      getWidgetSettings({
        cb: (err, res = {}) => {
          const { data = {} } = res
  
          const { modalBaseColor, modalSecondaryColor, modalNonPublic,
            whiteLabel, locale } = data
  
          this.setState({
            baseColor: modalBaseColor,
            darkColor: modalSecondaryColor || modalBaseColor,
            nonPublic: modalNonPublic,
            whiteLabel: whiteLabel,
            locale: locale,
            settingsLoaded: true
          })
        }
      })
      const storage = localStorage.getItem('act_public_storage')
      
      if (storage) {
        const storageData = JSON.parse(storage) || {}
        const storageUser = storageData.user || {}
  
        if (storageUser.email) {
          ssoLoginUser({ data: storageUser })
        }
      } 
    }

    if (!this.props.card) {
      getCard(params.feedbackId)
      return
    }

    if (card && card !== this.props.card) {
      loadComments(card.id)
      loadAllVotes(card.id)
    }

    if (user.id !== nextProps.user.id) {
      loadAllVotes(card.id)
    }
  }

  changelink = (link) => {
    const { history, location, organization } = this.props

    return history.push(`/v/${organization.slug || 'getacute'}${link}`)
  }

  goBack = () => {
    const { history, location, organization } = this.props

    history.push(`/v/${organization.slug}`)
  }

  _handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onDelete = () => {
    const { card, deleteCard, history, organization } = this.props

    deleteCard({ id: card.id, cb: (err, res = {}) => {
      if (err) {
        //TODO
      } else {
        history.push(`/v/${organization.slug}`)
      }
    }})
  }

  onSubmit = () => {
    const { title, body } = this.state
    const { history, submitCard, showModal, ssoUser } = this.props

    const submitParams = {
      title,
      body,
      tags: [],
      cb: (err, res = {}) => {
        if (err) {
          const { response = {} } = err
          const { data = {} } = response

          this.setState({ error: data.error })
        } else {
          const { nonPublic } = this.props
          const { data = {} } = res
          const { id } = data

          if (id) {
            if (nonPublic) {
              this.setState({ successMessage: true })
            } else {
              history.push({
                pathname: `/card/${id}`,
                state: {
                  backRoute: '/'
                }
              })
            }
          }
        }
      }
    }

    const storage = localStorage.getItem('act_public_storage')
    const data = JSON.parse(storage) || {}
    const { user, token } = data

    if (token) {
      submitCard(submitParams)
    } 
    // else if (!token && user) {
    //   ssoUser({ 
    //     data: user,
    //     userAction: submitCard.bind(null, submitParams),
    //     cb: (err, res = {}) => {
    //     if (err) {
    //       const { response = {} } = err
    //       const { data = {} } = response

    //       console.log(`SSO failed with error ${data.error}.`)
    //       showModal(submitCard.bind(null, submitParams))
    //     }
    //   }})
    // } 
    else {
      showModal(submitCard.bind(null, submitParams))
    }

  }

  onCommentSubmit = (formData) => {
    const { card = {}, submitComment, showModal, token } = this.props

    const submitParams = {
      id: card.id,
      formData: formData,
    }

    if (token) {
      submitComment(submitParams)
    } else {
      showModal(submitComment.bind(null, submitParams))
    }
  }

  handleImgClick = (img) => {
    this.setState({
      activeImg: img,
      imageModalOpen: true
    })
  }

  imageModal = () => {
    const { activeImg } = this.state

    return (
      <div class="modal is-active">
        <div class="modal-background" onClick={() => this.setState({activeImg: null, imageModalOpen: false})}></div>
        <div class="modal-content">
          <p style={{width: '100%',display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: 'inherit', maxWidth: '90vw', margin: '0 auto'}}>
            <img src={activeImg} alt="" style={{maxHeight: 'inherit', maxWidth: 'inherit'}}/>
          </p>
        </div>
      </div>
    )
  }

  content = () => {
    const { card, comments = [], user = {}, organization = {} } = this.props
    const { baseColor, darkColor, whiteLabel, title, body, error, imageModalOpen, locale } = this.state

    let buttonStyle = {
      backgroundColor: "4400cc"
    }

    let backLabel = translations["en"]["back"]
    let commentsLabel = translations["en"]["comments"]
    let commentInputLabel = translations["en"]["commentInput"]
    let daysAgoLabel = translations["en"]["days-ago"]
    let brandingLabel = translations["en"]["whitelabel"]

    if (translations[locale]) {
      backLabel = translations[locale]["back"]
      commentsLabel = translations[locale]["comments"]
      commentInputLabel = translations[locale]["commentInput"]
      daysAgoLabel = translations[locale]["days-ago"]
      brandingLabel = translations[locale]["whitelabel"]
    }
    
    return (
      <>
        <ModalRenderer darkColor={darkColor} />
        <SmallFeedbackHeader 
          baseColor={baseColor} 
          darkColor={darkColor} 
          whiteLabel={whiteLabel}
          cardView={true} 
          locale={locale}
        />

        <div class="container" style={{maxWidth: '730px', marginTop: '1.5rem', paddingBottom: '3rem', paddingLeft: '1.5rem', paddingRight: '1.5rem'}}> 
          <button 
            class="button is-white" 
            style={{backgroundColor: 'transparent', marginBottom: '1.5em'}}
            onClick={() => this.goBack()}
          >← {backLabel}</button>
          
          {card ? <FullCard 
            card={card} 
            darkColor={darkColor} 
            user={user}
            onCommentSubmit={this.onCommentSubmit}
            onDelete={this.onDelete}
            handleImgClick={this.handleImgClick}
            locale={locale}
          /> : null}

        { card && card.pending ? 
          <article class="media" style={{margin: 0,
            padding: 0,
            borderTop: 0,
            justifyContent: 'center'
          }}>
          <div class="alert-success">
              <span class="alert-icon">
                <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path>
                </svg>
              </span>
              <span class="alert-message">
                Your feedback has been saved and will be visible after approval.
              </span>
            </div>
          </article>
        : null }

          <br />

          <div class="box">
            <div class="tabs">
              <ul>
                <li class="is-active"><a>{commentsLabel}</a></li>
              </ul>
            </div>
            <BulmaCommentList locale={locale} comments={comments} handleImgClick={this.handleImgClick} />
          </div>
          <div class="app-footer">
            <p class="branding">
              <a href={`https://getacute.io/acute-link?utm_source=feedback_page&utm_medium=powered&utm_campaign=${organization.name}&company=${organization.name}`} target="_new">
                {brandingLabel} <span style={{textDecoration: 'underline'}}>Acute</span>
              </a>
            </p>
          </div>
        </div>
        {imageModalOpen && this.imageModal()}
      </>
    )
  }

  render() {
    const { card = {} } = this.props
    const { settingsLoaded } = this.state

    if (settingsLoaded === false) { 
      return (
        <></>
      )
    } else {
      return this.content()
    }
  }
}