import React from 'react'

import timeDifference from '../../utils/timeDifference'
import CommentInput from '../CommentInput'
import StarIcon from '../../shared/assets/icons/star.svg'
import BulmaCommentInput from '../BulmaCommentInput'
import Autolink from '../../utils/Autolink'

import { Container, Img, AuthorDetails, FullName, Badge,
  BodyContainer, ImgContainer, ActionsContainer, ActionLink, Dot,
  Tag , AdminBadge
} from './style'

const translations = {
  "en": {
    "delete": "Delete"
  },
  "fr": {
    "delete": "Effacer",
  },
  "pt": {
    "delete": "Excluir",
  }
}

export default class Comment extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditing: false
    }
  }

  handleDelete = () => {
    const { data = {}, deleteComment } = this.props

    deleteComment(data.id)
  }

  handleEdit = () => {
    this.setState({ isEditing: true })
  }

  handleSubmit = ({comment}) => {
    const { updateComment, data } = this.props
    const { id } = data

    this.setState({ isEditing: false })
    updateComment({id, comment})
  }

  closeEditing = () => {
    this.setState({ isEditing: false })
  }

  body = () => {
    const { isEditing } = this.state
    const { data = {} } = this.props

    return (
      isEditing ? 
        <BulmaCommentInput 
          value={data.body}
          isOpen={true}
          onSubmit={this.handleSubmit}
          edit={true}
          onCancel={this.closeEditing}
        />
        : <p>
          <Autolink text={data.body} />
        </p>
    )
  }

  render() {
    const { data = {}, user = {}, handleImgClick, locale } = this.props
    const { isEditing } = this.state
    const { author = {}, images = [] } = data
    const { id, admin, avatarURL, fullName } = author

    let deleteLabel = translations["en"]["delete"]

    if (translations[locale]) {
      deleteLabel = translations[locale]["delete"]
    }

    return (
      <>
      <article key={data.id} class="media">
          <figure class="media-left">
            <p class="image is-32x32">
              <Img
                src={avatarURL}
                alt=""
              />
              {admin &&
                <AdminBadge
                  src={StarIcon}
                  alt=""
                />
              }
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <nav class="level small-level">
                <div class="level-left">
                  <span class="level-item" style={{justifyContent: 'left'}}>
                    <FullName
                      admin={admin}
                      style={admin ? { color: '#4400CC', marginBottom: 0 } : { color: '#4a4a4a', marginBottom: 0 }}
                    >
                      {fullName}
                    </FullName>
                    <Dot>•</Dot>
                    <span style={{ color: '#657382', fontSize: '12px', marginTop: '2px'}}>
                      {timeDifference(data.createdAt)}
                    </span>
                  </span>
                </div>
                <div class="level-right">
                  {data.nonPublic &&
                    <span class="level-item">
                      <span class="tag is-private is-small is-light">Private</span>
                    </span>
                  }
                </div>
              </nav>
              <nav class="level">
                { isEditing ? this.body()
                  : <>
                  <div class="">
                    <span class="level-item" style={{justifyContent: 'left'}}>
                      {this.body()}
                    </span>
                    { images.map(img => 
                    <>
                    <br />
                    <figure className="image comment-img-figure" key={img}>
                      <img src={img} 
                        onClick={() => handleImgClick(img)}
                        className="comment-img"
                      />
                    </figure>
                    </>)}
                  </div>
                  { author.id === user.id ?
                    <div class="level-right">
                    <span class="level-item">
                      <a class="button is-text is-small" onClick={this.handleDelete}>{deleteLabel}</a>
                    </span>
                  </div>
                  : null }
                  </>
                }
              </nav>
            </div>
          </div>
      </article>
      { author.id === user.id && data.pending ? 
              <article class="media" style={{margin: 0,
                padding: 0,
                borderTop: 0,
                justifyContent: 'center'
              }}>
              <div class="alert-success">
                  <span class="alert-icon">
                    <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="check-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path>
                    </svg>
                  </span>
                  <span class="alert-message">
                    Your comment has been saved and will be visible after approval.
                  </span>
                </div>
              </article>
            : null }
      </>
    )
  }
}