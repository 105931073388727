import styled from 'styled-components'
import theme from '../../shared/theme'

export const Wrapper = styled.p`
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  white-space: nowrap;
  ${props => props.color && 
    `color: ${props.color};`}
`