import React from 'react'

import VoteButton from '../VoteButton'
import CommentIcon from '../../shared/assets/icons/comment.svg'
import StateLabel from '../StateLabel'
import { ReactComponent as Trash } from '../../shared/assets/icons/trash.svg'
import StarIcon from '../../shared/assets/icons/star.svg'
import timeDifference from '../../utils/timeDifference'
import BulmaCommentInput from '../BulmaCommentInput'
import Autolink from '../../utils/Autolink'

import { FullName, GreenBadge } from './style'

const translations = {
  "en": {
    "whitelabel": "Powered by",
    "back": "Back",
    "comments": "Comments",
    "commentInput": "Leave a comment...",
    "days-ago": "days ago",
    "months-ago": "months ago"
  },
  "fr": {
    "whitelabel": "Optimisé par",
    "back": "Retour",
    "comments": "Commentaires",
    "commentInput": "Laissez un commentaire...",
    "days-ago": "jours il y a",
    "months-ago": "mois il y a"
  },
  "pt": {
    "whitelabel": "Distribuído por",
    "back": "Voltar",
    "comments": "Comentários",
    "commentInput": "Deixe um comentário...",
    // "days-ago": "Há dias",
    // "months-ago": ""
  },
}

export default class Card extends React.Component {
  upvote = () => {
    const { card = {}, submitUpvote, showModal, token } = this.props

    if (token) {
      submitUpvote(card.id)
    } else {
      showModal(submitUpvote.bind(null, card.id))
    }
  }

  downvote = () => {
    const { card = {}, submitDownvote, showModal, token } = this.props

    if (token) {
      submitDownvote(card.id)
    } else {
      showModal(submitDownvote.bind(null, card.id))
    }
  }

  onVoteClick = (e) => {
    const { vote = {} } = this.props
    e.stopPropagation()

    vote.has_voted ? this.downvote() : this.upvote()
  }

  render() {
    const { card = {}, style, darkColor, user = {}, vote = {}, 
      onCommentSubmit, onDelete, handleImgClick, locale } = this.props
    const { author = {}, images = [] } = card

    let backLabel = translations["en"]["back"]
    let commentsLabel = translations["en"]["comments"]
    let commentInputLabel = translations["en"]["commentInput"]
    let daysAgoLabel = translations["en"]["days-ago"]
    let brandingLabel = translations["en"]["whitelabel"]

    if (translations[locale]) {
      backLabel = translations[locale]["back"]
      commentsLabel = translations[locale]["comments"]
      commentInputLabel = translations[locale]["commentInput"]
      daysAgoLabel = translations[locale]["days-ago"]
      brandingLabel = translations[locale]["whitelabel"]
    }

    return (
      <div class="box">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <nav class="level" style={{marginBottom: '1em'}}>
                <div>
                  <span class="level-item" style={{justifyContent: 'left'}}>
                    <p class="title is-5 is-spaced">
                      {card.title}
                    </p>
                  </span>
                </div>
              </nav>
              <p class="subtitle is-5" style={{whiteSpace: 'pre-wrap'}}>
                <Autolink text={card.body} />
              </p>
              {images.map(img =>
                <> 
                  <figure className="image card-img-figure" key={img}>
                    <img src={img} 
                      onClick={() => handleImgClick(img)}  
                      class="card-img"
                    />
                  </figure>
                  <br />
              </>)}
              <article className="media" style={{alignItems: 'center', borderTop: 'none'}}>
                  <figure className="media-left" style={{ marginLeft: 'unset', marginTop: 'unset', marginBottom: 'unset', marginRight: '1em'}}>
                    <p className="image is-36x36">
                      <img className="is-rounded" src={card.author && card.author.avatarURL} style={{width: '36px', height: '36px'}} />
                      { author.admin &&
                        <GreenBadge
                          src={StarIcon}
                          alt=""
                        />
                      }
                    </p>
                  </figure>
                  <div className="media-content">
                    <div className="content">
                      <p style={{ marginBottom: '0'}}>
                        <FullName
                          admin={author.admin}
                          style={author.admin ? {color: '#4400CC'} : {color: '#4a4a4a'}}
                        >
                          {author.fullName}
                        </FullName>
                      </p>
                      <small>{card.createdAt && timeDifference(card.createdAt)}</small>
                    </div>
                  </div>
                </article>
            </div>
          </div>
          <div class="media-right">
            <VoteButton
              active={vote.has_voted}
              onClick={this.onVoteClick}
              value={vote.votes || card.votes}
              noMargin
              darkColor={darkColor}
            />
            { author.id === user.id ?
              <div className="aui-card__view-comments-button" onClick={onDelete}>
                <Trash width="16px" height="16px" alt=""/>
              </div>
            : null }
          </div>
        </article>
          <BulmaCommentInput
            onSubmit={onCommentSubmit}
            allowPrivate={false}
            avatar={user.avatarURL}
            admin={user.admin}
            darkColor={darkColor}
            locale={locale}
          />
      </div>
    )
  }
}