import styled from 'styled-components'

import theme from '../../../shared/theme'

export const Container = styled.div`
  background-color: ${theme.bg.default};
  width: 100%;
  min-height: 100vh;
  background-color: #f5f7fa;
  position: relative;
`