import React from 'react'
import { Route } from 'react-router-dom'

import { App } from '../components/Layout'

const routeComponent = ({ component: Component, props }) => {
  return (
    <App>
      <Component {...props} />
    </App>
  )
}

const AppRoute = ({ component, token, ...rest }) => (
  <Route {...rest} render={(props) => (
    routeComponent({ component, props })
  )} />
)

export default AppRoute