import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { actions as Actions } from 'acute-public-uilib'
import { constants as Constants } from 'acute-public-uilib'
import PublicFeedbackView from './PublicFeedbackView'

const mapStateToProps = (state = {}, ownProps = {}) => {
  const { data } = state
  const { organisation = {}, cards = [], loading = {} } = data

  const filteredCards = Object.values(cards)

  return {
    organization: organisation,
    cards: filteredCards,
    loading: loading.isFetching
  }
}

const mapDispatchToProps = dispatch => ({
  getOrg: ({ domain, cb } = {}) => {
    dispatch(Actions.org.getByDomain({ domain, cb }))
  },
  ssoLoginUser: ({ data, cb } = {}) => {
    dispatch(Actions.user.ssoLogin({ data, cb }))
  },
  getWidgetSettings: ({ cb } = {}) => {
    dispatch(Actions.widget.getSettings({ cb }))
  },
  loadCards: ({limit, skip}) => {
    const { card: { States = {} } } = Constants

    dispatch(Actions.card.getByState({state: States.REQUESTED, limit, skip}))
  },
  loadMoreCards: ({limit, skip, cb}) => {
    const { card: { States = {} } } = Constants

    dispatch(Actions.card.getMoreByState({state: States.REQUESTED, limit, skip, cb}))
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicFeedbackView))
