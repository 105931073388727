import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'react-router-redux'
import { createBrowserHistory } from 'history'

import './index.css';
import AcuteUI from './components/Acute'
import * as serviceWorker from './serviceWorker'
import genStoreWrapper from './StoreWrapper'

const history = createBrowserHistory()
const PrivateContext = React.createContext()
const StoreWrapper = genStoreWrapper()

export default class AdminApp extends Component {
  render() {
    return (
      <StoreWrapper context={PrivateContext}>
        <ConnectedRouter history={history} context={PrivateContext}>
          <AcuteUI context={PrivateContext} />
        </ConnectedRouter>
      </StoreWrapper>
    )
  }
}