import React from 'react'
// import { Columns, Column } from 'bloomer'
// import Box from '../../../../components/Box'
import { Spinner, Icon, Tooltip } from '@blueprintjs/core'
import SearchInput from '../../components/SearchInput'
import VoteButton from '../../components/VoteButton'
import CommentIcon from '../../shared/assets/icons/comment.svg'
import Card from '../../components/Card'
import CustomDomainSmallFeedbackHeader from '../../components/CustomDomainSmallFeedbackHeader'
import ModalRenderer from '../../components/ModalRenderer'
// import TableDropDown from '../../../../components/TableDropDown'
// import Label from '../../../../components/Label'
// import AddFeedbackModal from '../../../../components/AddFeedbackModal'
// import CardsEmptyState from '../../../DashboardView/components/CardsEmptyState'
// // import statusOptions from '../../statusOptions'
// import sortOptions from '../../sortOptions'
// import stateOptions from '../../stateOptions'
// import InfoImg from '../../../../shared/assets/icons/info-circle-solid.svg'
// import { ReactComponent as SortDown } from '../../../../shared/assets/icons/sort-down.svg'
// import { ReactComponent as SortUp } from '../../../../shared/assets/icons/sort-up.svg'
// import { ReactComponent as QuestionIcon } from '../../../../shared/assets/icons/question-circle-solid.svg'
// import sortCards from '../../../../utils/sortCards'

// import { renderRows, renderBulmaRows } from './helpers'
// import { Wrapper, SwitchWrapper } from './style'
import './style.css'

const translations = {
  "en": {
    "message-1": "Let us know what features would make",
    "message-2": "more useful for you.",
    "title": "Short title",
    "email": "Email",
    "description": "Describe the feature",
    "submit": "Submit",
    "whitelabel": "Powered by",
    "back": "Back"
  },
  "fr": {
    "message-1": "Faites-nous savoir quelles fonctionnalités seraient",
    "message-2": "utiles pour vous.",
    "title": "Titre",
    "email": "Email",
    "description": "Description",
    "submit": "Soumettre",
    "whitelabel": "Optimisé par",
    "back": "Retour"
  },
  "pt": {
    "message-1": "Deixe-nos saber quais recursos tornariam o",
    "message-2": "mais útil para você.",
    "title": "Sugestões",
    "email": "Email",
    "description": "Descrição",
    "submit": "Enviar",
    "whitelabel": "Distribuído por",
    "back": "Voltar",
  }
}

export default class PublicFeedbackView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      settingsLoaded: false,
      title: '',
      body: '',
      files: [],
      loading: false
    }
  }

  componentWillMount() {
    document.body.classList.add('public-view-page');
  } 

  componentWillReceiveProps(nextProps) {
    const { organization } = nextProps
    const { getWidgetSettings, ssoLoginUser } = this.props

    if (organization !== this.props.organization) {
      getWidgetSettings({
        cb: (err, res = {}) => {
          const { data = {} } = res
  
          const { modalBaseColor, modalSecondaryColor, modalNonPublic,
            whiteLabel, locale } = data
  
          this.setState({
            baseColor: modalBaseColor,
            darkColor: modalSecondaryColor || modalBaseColor,
            nonPublic: modalNonPublic,
            whiteLabel: whiteLabel,
            locale: locale,
            settingsLoaded: true
          })
        }
      })
      const storage = localStorage.getItem('act_public_storage')
      
      if (storage) {
        const storageData = JSON.parse(storage) || {}
        const storageUser = storageData.user || {}
  
        if (storageUser.email) {
          ssoLoginUser({ data: storageUser })
        }
      } 
    }
  }

  componentDidMount() {
    const { getOrg, match = {} } = this.props
    const domain = window.location.host

    getOrg({domain: domain,
      cb: (err, res = {}) => {
        if (res) {
          const { org = {} } = res
          if (org.name) {
            document.title = `${org.name} Feedback`

            document
            .getElementsByTagName('meta')
            .namedItem('description')
            .setAttribute('content', `Give feedback to the ${org.name} team.`)
          }

          if (org.faviconUrl) {
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/ico';
            link.rel = 'icon';
            link.sizes = "16x16 32x32"
            link.href = org.faviconUrl;
            document.getElementsByTagName('head')[0].appendChild(link);
          }
        } else {
          this.setState({error: true})
        }
      }
    })    
  }

  changelink = (link) => {
    const { history, location, organization } = this.props

    return history.push(`${link}`)
  }

  goBack = () => {
    const { history, location,organization } = this.props

    return history.push(`/`)
  }

  _handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onSubmit = () => {
    const { title, body, files } = this.state
    const { history, submitCard, showModal, ssoUser, token } = this.props

    this.setState({loading: true})

    let formData = new FormData()

    files.map((file, index) => {
      formData.append('files[]', file)
    })
    formData.append('title', title)
    formData.append('body', body)
    formData.append('tags', [])

    const submitParams = {
      formData,
      cb: (err, res = {}) => {
        if (err) {
          const { response = {} } = err
          const { data = {} } = response

          this.setState({ error: data.error, loading: false })
        } else {
          const { nonPublic } = this.props
          const { data = {} } = res
          const { id } = data

          if (id) {
            if (nonPublic) {
              this.setState({ successMessage: true, loading: false })
            } else {
              history.push({
                pathname: `/feedback/${id}`,
                state: {
                  backRoute: '/'
                }
              })
            }
          }
        }
      }
    }

    // const storage = localStorage.getItem('act_public_storage')
    // const data = JSON.parse(storage) || {}
    // const { user, token } = data

    // console.log(user)
    // console.log(token)

    if (token) {
      submitCard(submitParams)
    } 
    // else if (!token && user) {
    //   ssoUser({ 
    //     data: user,
    //     userAction: submitCard.bind(null, submitParams),
    //     cb: (err, res = {}) => {
    //     if (err) {
    //       const { response = {} } = err
    //       const { data = {} } = response

    //       console.log(`SSO failed with error ${data.error}.`)
    //       showModal(submitCard.bind(null, submitParams))
    //     }
    //   }})
    // } 
    else {
      showModal(submitCard.bind(null, submitParams))
    }

  }

  onImgUpload = (e) => {
    const { updateLogo } = this.props
    let { formData } = this.state 
    let error = false
    const file = e.target.files[0]

    const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']

    if (!file) return

    if (types.every(type => file.type !== type)) {
      console.log('Wrong file type. Expected .png, .jpeg or .jpg')
      error = true
      this.setState({error: 'Wrong file type. Expected .png, .jpeg or .jpg'})
    }

    if (!error) {
      this.setState({
        files: [...this.state.files, file],
      })
    }
  }


  removeImg = (file) => {
    let { files } = this.state

    const index = files.indexOf(file)
    files.splice(index, 1)

    this.setState({
      files: [...files]
    })
  }

  content = () => {
    const {  cards = [], organization } = this.props
    const { baseColor, darkColor, whiteLabel, title, body, error, files, loading, locale } = this.state

    let backLabel = translations["en"]["back"]
    let firstMessage = translations["en"]["message-1"]
    let secondMessage = translations["en"]["message-2"]
    let titleLabel = translations["en"]["title"]
    let descriptionLabel = translations["en"]["description"]
    let submitLabel = translations["en"]["submit"]
    let brandingLabel = translations["en"]["whitelabel"]

    if (translations[locale]) {
      backLabel = translations[locale]["back"]
      firstMessage = translations[locale]["message-1"]
      secondMessage = translations[locale]["message-2"]
      titleLabel = translations[locale]["title"]
      descriptionLabel = translations[locale]["description"]
      submitLabel = translations[locale]["submit"]
      brandingLabel = translations[locale]["whitelabel"]
    }

    let buttonStyle = {
      backgroundColor: "4400cc"
    }
    
    return (
      <>
        <ModalRenderer darkColor={darkColor} />
        <CustomDomainSmallFeedbackHeader 
          baseColor={baseColor} 
          darkColor={darkColor} 
          whiteLabel={whiteLabel} 
          locale={locale}
        />

        <div class="container" style={{maxWidth: '730px', marginTop: '1.5rem', paddingBottom: '3rem', paddingLeft: '1.5rem', paddingRight: '1.5rem'}}>
          <button 
            class="button is-white" 
            style={{backgroundColor: 'transparent', marginBottom: '1.5em'}}
            onClick={() => this.goBack()}
          >← {backLabel}</button>
          
          <div className="error__container">{ error }</div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <div class="control">
                  <input 
                    class="input is-medium" 
                    type="text" 
                    placeholder={titleLabel}
                    value={title}
                    name="title"
                    onChange={this._handleChange}
                    />
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <textarea 
                    class="textarea is-medium" 
                    placeholder={descriptionLabel}
                    name="body"
                    value={body} 
                    onChange={this._handleChange}
                  />
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <nav class="level">
                    <div class="level-left">
                    {files.map((file, index) =>  <div class="level item upload-img-preview" key={index} style={{position: 'relative', marginBottom: 0, height: '105px'}}>
                      <img src={URL.createObjectURL(file)} className="post-img-preview" />
                      <span 
                        class="icon is-small is-right" 
                        style={{
                          cursor: 'pointer',
                          top: '5px',
                          cursor: 'pointer',
                          position: 'absolute',
                          right: '9px',
                        }} 
                        onClick={() => this.removeImg(file)}
                      >
                        <Icon icon={'cross'} />
                      </span>
                      </div>
                      )}
                      <div class="level-item file">
                      {files.length < 2 ? 
                        <label class="file-label">
                        <Tooltip content={"Add an image"}>
                          <div className="img-upload-container">
                              <input
                                class="file-input"
                                type="file" 
                                id='Logoupload'
                                onChange={this.onImgUpload}
                                style={{cursor: 'pointer'}}
                              />

                            <Icon
                              icon='media'
                              iconSize={13}
                              class="file-cta"
                              style={{cursor: 'pointer'}}
                            />
                          </div>
                          </Tooltip>
                          </label>
                      : null }
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              <div class="field is-grouped is-grouped-centered">
                <p class="control">
                  <button 
                    className={loading ? "button submit-button is-loading" : "button submit-button"}
                    onClick={this.onSubmit}
                    style={{
                      color: '#fff',
                      backgroundColor: darkColor || '#4400cc',
                      borderColor: darkColor || '#4400cc',
                     }}>
                    {submitLabel}
                  </button>
                </p>
              </div>
            </div>
          </div>
          <div class="app-footer">
            <p class="branding">
              <a href={`https://getacute.io/acute-link?utm_source=feedback_page&utm_medium=powered&utm_campaign=${organization.name}&company=${organization.name}`} target="_new">
                {brandingLabel} <span style={{textDecoration: 'underline'}}>Acute</span>
              </a>
            </p>
          </div>
        </div>
      </>
    )
  }

  render() {
    const { cards = [] } = this.props
    const { settingsLoaded } = this.state

    if (settingsLoaded === false) { 
      return (
        <></>
      )
    } else {
      return this.content()
    }
  }
}