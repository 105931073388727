export default [{
  label: 'Requested',
  value: 'req',
  bgColor: 'rgb(230, 232, 238)',
  color: 'rgb(66, 82, 110)',
  next: 'planned'
}, {
  label: 'Under Review',
  value: 'ur',
  bgColor: 'rgb(239 221 255)',
  bgLightColor: 'rgb(239 221 255)',
  color: 'rgb(123, 38, 197)',
  next: 'planned'
}, {
  label: 'Planned',
  value: 'planned',
  bgColor: 'rgb(222, 235, 255)',
  bgLightColor: 'rgba(7, 71, 252, 1)',
  color: 'rgb(7, 71, 166)',
  next: 'wip',
}, {
  label: 'Rejected',
  value: 'rejected',
  bgColor: '#ffdede',
  color: '#a60707',
  next: 'req'
}, {
  label: 'In Progress',
    value: 'wip',
  bgColor: '#ffecdf',
  bgLightColor: 'rgb(255, 111, 13)',
  color: '#aa4a09',
  next: 'done'
}, {
  label: 'Completed',
  value: 'done',
  bgColor: 'rgb(227, 252, 239)',
  bgLightColor: 'rgb(2, 186, 125)',
  color: 'rgb(0, 102, 68)',
  next: 'req'
}]