import React from 'react'
// import { Columns, Column } from 'bloomer'
// import Box from '../../../../components/Box'
import { Spinner, Icon, Tooltip } from '@blueprintjs/core'
import SearchInput from '../../components/SearchInput'
import VoteButton from '../../components/VoteButton'
import CommentIcon from '../../shared/assets/icons/comment.svg'
import Card from '../../components/Card'
import FeedbackHeader from '../../components/FeedbackHeader'
import filterCards from '../../utils/filterCards'
import SmallFeedbackHeader from '../../components/SmallFeedbackHeader'
import ModalRenderer from '../../components/ModalRenderer'
import Autolink from '../../utils/Autolink'
// import TableDropDown from '../../../../components/TableDropDown'
// import Label from '../../../../components/Label'
// import AddFeedbackModal from '../../../../components/AddFeedbackModal'
// import CardsEmptyState from '../../../DashboardView/components/CardsEmptyState'
// // import statusOptions from '../../statusOptions'
// import sortOptions from '../../sortOptions'
// import stateOptions from '../../stateOptions'
// import InfoImg from '../../../../shared/assets/icons/info-circle-solid.svg'
// import { ReactComponent as SortDown } from '../../../../shared/assets/icons/sort-down.svg'
// import { ReactComponent as SortUp } from '../../../../shared/assets/icons/sort-up.svg'
// import { ReactComponent as QuestionIcon } from '../../../../shared/assets/icons/question-circle-solid.svg'
// import sortCards from '../../../../utils/sortCards'

// import { renderRows, renderBulmaRows } from './helpers'
// import { Wrapper, SwitchWrapper } from './style'
import './style.css'

const translations = {
  "en": {
    "whitelabel": "Powered by",
    "under-review": "Under Review",
    "planned": "Planned",
    "in-progress": "In progress",
    "completed": "Completed",
  },
  "fr": {
    "whitelabel": "Optimisé par",
    "under-review": "À l'étude",
    "planned": "Prévus",
    "in-progress": "En cours",
    "completed": "Termines",
  },
  "pt": {
    "whitelabel": "Distribuído por",
    "under-review": "Sob revisão",
    "planned": "Planejado",
    "in-progress": "Em progresso",
    "completed": "Completo",
  },
}

const BulmaRoadMapColumn = ({ stateValue, cards = [], org = {}}) => {
  const stateCards = cards.filter(card => card.state === stateValue)
  let label = 'planned'

  if (stateValue === 'wip') label = 'in progress'
  if (stateValue === 'done') label = 'completed'

  return (
    stateCards.length > 0 ?
      stateCards.map(card => { 
        let body = ''

        if (card.body) {
         body = card.body.length < 97 ? card.body : (card.body.substring(0, 97) + '...')
        }

        return (
        <div class="box">
          <article class="media" key={card.id}>
            <div class="media-content">
              <a style={{ color: '#363636', textDecoration: 'none' }} href={`/v/${org.slug}/feedback/` + card.id}>
                <div class="content">
                  <p class="title is-6 is-spaced">
                    {card.title.length < 36 ? card.title : (card.title.substring(0, 36) + '...')}
                  </p>
                  <p class="subtitle is-6">
                    <Autolink 
                      text={body}
                    />
                  </p>
                </div>
              </a>
            </div>
          </article>
        </div>
      )})
  :
    null
  )
}


export default class PublicFeedbackView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      settingsLoaded: false
    }
  }

  componentWillMount() {
    document.body.classList.add('public-view-page');
  } 

  componentWillReceiveProps(nextProps) {
    const { organization } = nextProps
    const { getWidgetSettings, loadCards } = this.props

    if (organization !== this.props.organization) {
      getWidgetSettings({
        cb: (err, res = {}) => {
          const { data = {} } = res
  
          const { modalBaseColor, modalSecondaryColor, modalNonPublic,
            whiteLabel, locale } = data
  
          this.setState({
            baseColor: modalBaseColor,
            darkColor: modalSecondaryColor || modalBaseColor,
            nonPublic: modalNonPublic,
            whiteLabel: whiteLabel,
            locale: locale,
            settingsLoaded: true,
          })
        }
      })
      loadCards()
    }
  }

  componentDidMount() {
    const { getOrg, match = {} } = this.props
    const { params = {} } = match

    getOrg({slug: params.id, 
      cb: (err, res = {}) => {
        if (res) {
          const { org = {} } = res

          if (org.publicViewRoadmapActive === false) {
            this.changelink("/")
          }
          
          if (org.name) {
            document.title = `${org.name} Feedback`

            document
            .getElementsByTagName('meta')
            .namedItem('description')
            .setAttribute('content', `Give feedback to the ${org.name} team.`)
          }

          if (org.faviconUrl) {
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/ico';
            link.rel = 'icon';
            link.sizes = "16x16 32x32"
            link.href = org.faviconUrl;
            document.getElementsByTagName('head')[0].appendChild(link);
          }
        } else {
          this.setState({error: true})
        }
      }
    })    
  }

  changelink = (link) => {
    const { history, location, organization } = this.props

    return history.push(`/v/${organization.slug || 'getacute'}${link}`)
  } 

  roadmap = () => {
    return (
      <>
        <nav class="navbar is-transparent is-acute is-public" role="navigation" aria-label="main navigation">
          <div class="navbar-brand">
            <a class="navbar-item" href="https://getacute.io" target="_blank">
              <img src="/images/acute_icon.png" style={{height: '28', width: 'auto'}} />
            </a>
          </div>

          <a class="navbar-item is-link">
            Feedback
          </a>

          <a class="navbar-item is-link is-active">
            Roadmap
          </a>

          <div class="navbar-end">
            <div class="navbar-item">
              <a class="button is-primary powered">
                <strong>Powered by Acute</strong>
              </a>
            </div>
          </div>
        </nav>
        <nav class="navbar is-transparent is-acute is-public is-second" style={{marginTop: '-30px'}}>
          <div style={{textAlign: 'center'}}>
            <h1 class="header title is-spaced">Acute Roadmap</h1>
          </div>
        </nav>

        <div class="container" style={{marginTop: '1.5rem'}}>
          <div class="columns">
            <div class="column">
              <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1.5rem'}}>
                <div class="status-dot-blue" />
                <span class="title is-5"><strong>Planned</strong></span>
              </div>
              <div class="box">
                <article class="media">
                  <div class="media-content">
                    <div class="content">
                      <nav class="level" style={{marginBottom: '1em'}}>
                        <div>
                          <span class="level-item">
                            <p class="title is-6 is-spaced">
                              <a style={{ color: '#363636' }} href={'/feedback/' + 1}>
                                Feature request
                              </a>
                            </p>
                          </span>
                        </div>
                      </nav>
                      {/* {card.state !== 'req' &&
                        <StateLabel
                          value={card.state}
                        />
                      } */}
                      <p class="subtitle is-6">Body</p>
                    </div>
                  </div>
                </article>
            </div>
            </div>
            <div class="column">
              <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1.5rem'}}>
                <div class="status-dot-yellow" />
                <span class="title is-5"><strong>In Progress</strong></span>
              </div>
              <div class="box">
                <article class="media">
                <div class="media-content">
                  <div class="content">
                    <nav class="level" style={{marginBottom: '1em'}}>
                      <div>
                        <span class="level-item">
                          <p class="title is-6 is-spaced">
                            <a style={{ color: '#363636' }} href={'/feedback/' + 1}>
                              Feature request
                            </a>
                          </p>
                        </span>
                      </div>
                    </nav>
                    <p class="subtitle is-6"></p>
                  </div>
                </div>
              </article>
              </div>
              <div class="box">
                <article class="media">
                <div class="media-content">
                  <div class="content">
                    <nav class="level" style={{marginBottom: '1em'}}>
                      <div>
                        <span class="level-item">
                          <p class="title is-6 is-spaced">
                            <a style={{ color: '#363636' }} href={'/feedback/' + 1}>
                              Feature request
                            </a>
                          </p>
                        </span>
                      </div>
                    </nav>
                    <p class="subtitle is-6">Body</p>
                  </div>
                </div>
              </article>
              </div>
            </div>
            <div class="column">
            <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1.5rem'}}>
                <div class="status-dot-green" />
                <span class="title is-5"><strong>Completed</strong></span>
              </div>
            <div class="box">
            {/* <article class="media">
              <SearchInput
                onSubmit={this.onSearch}
                large
                placeholder="Search feedback"
                withIcon
                noBorder
                //value={this.state.searchValue}
              />
              <div class="add-button">
                Make a suggestion
              </div>
            </article> */}
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <nav class="level" style={{marginBottom: '1em'}}>
                    <div>
                      <span class="level-item">
                        <p class="title is-6 is-spaced">
                          <a style={{ color: '#363636' }} href={'/feedback/' + 1}>
                            Feature request
                          </a>
                        </p>
                      </span>
                    </div>
                  </nav>
                  {/* {card.state !== 'req' &&
                    <StateLabel
                      value={card.state}
                    />
                  } */}
                  <p class="subtitle is-6">Body</p>
                </div>
              </div>
            </article>
            </div>
            <div class="box">
                <article class="media">
                <div class="media-content">
                  <div class="content">
                    <nav class="level" style={{marginBottom: '1em'}}>
                      <div>
                        <span class="level-item">
                          <p class="title is-6 is-spaced">
                            <a style={{ color: '#363636' }} href={'/feedback/' + 1}>
                              Feature request
                            </a>
                          </p>
                        </span>
                      </div>
                    </nav>
                    <p class="subtitle is-6"></p>
                  </div>
                </div>
              </article>
              </div>
              <div class="box">
                <article class="media">
                <div class="media-content">
                  <div class="content">
                    <nav class="level" style={{marginBottom: '1em'}}>
                      <div>
                        <span class="level-item">
                          <p class="title is-6 is-spaced">
                            <a style={{ color: '#363636' }} href={'/feedback/' + 1}>
                              Feature request
                            </a>
                          </p>
                        </span>
                      </div>
                    </nav>
                    <p class="subtitle is-6"></p>
                  </div>
                </div>
              </article>
              </div>
                  
            </div>
          </div>
        </div>
      </>
    )
  }

  onSearchChange = (e) => {
    const { value } = e.target

    this.setState(() => ({ searchValue: value }))
  }

  content = () => {
    const {  cards = [], loading = false, organization } = this.props
    const { settingsLoaded, baseColor, darkColor, whiteLabel, searchValue, locale } = this.state

    let plannedLabel = translations["en"]["planned"]
    let underReviewLabel = translations["en"]["under-review"]
    let inProgressLabel = translations["en"]["in-progress"]
    let completedLabel = translations["en"]["completed"]
    let brandingLabel = translations["en"]["whitelabel"]

    if (translations[locale]) {
      plannedLabel = translations[locale]["planned"]
      underReviewLabel = translations[locale]["under-review"]
      inProgressLabel = translations[locale]["in-progress"]
      completedLabel = translations[locale]["completed"]
      brandingLabel = translations[locale]["whitelabel"]
    }
    
    return (
      <>
        <ModalRenderer darkColor={darkColor} />
        <SmallFeedbackHeader 
          baseColor={baseColor} 
          darkColor={darkColor} 
          whiteLabel={whiteLabel}
          roadmap={true}
          locale={locale}
        />

        {
          loading ? <div style={{ position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center' }}>
              <Spinner />
            </div>
          :  <div class="container" style={{marginTop: '1.5rem', paddingBottom: '3rem', paddingLeft: '1.5rem', paddingRight: '1.5rem'}}>
          <div class="columns">
          <div class="column">
              <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1.5rem'}}>
                <div class="status-dot-purple" />
                <span class="title is-5"><strong>{underReviewLabel}</strong></span>
              </div>
              <BulmaRoadMapColumn
                stateValue='ur'
                cards={cards}
                org={organization}
              />
            </div>
            <div class="column">
              <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1.5rem'}}>
                  <div class="status-dot-blue" />
                  <span class="title is-5"><strong>{plannedLabel}</strong></span>
                </div>
                <BulmaRoadMapColumn
                  stateValue='planned'
                  cards={cards}
                  org={organization}
                />
            </div>
            <div class="column">
              <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1.5rem'}}>
                <div class="status-dot-yellow" />
                <span class="title is-5"><strong>{inProgressLabel}</strong></span>
              </div>
              <BulmaRoadMapColumn
                  stateValue='wip'
                  cards={cards}
                  org={organization}
                />
            </div>
          </div>
          <div class="app-footer">
            <p class="branding">
              <a href={`https://getacute.io/acute-link?utm_source=feedback_page&utm_medium=powered&utm_campaign=${organization.name}&company=${organization.name}`} target="_new">
                {brandingLabel} <span style={{textDecoration: 'underline'}}>Acute</span>
              </a>
            </p>
          </div>
        </div>
        }
      </>
    )
  }

  render() {
    const { cards = [] } = this.props
    const { settingsLoaded } = this.state

    if (settingsLoaded === false) { 
      return (
        <></>
      )
    } else {
      return this.content()
    }
  }
}