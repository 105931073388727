import React from 'react'
// import { Columns, Column } from 'bloomer'
// import Box from '../../../../components/Box'
import { Spinner, Icon, Tooltip } from '@blueprintjs/core'
import SearchInput from '../../components/SearchInput'
import VoteButton from '../../components/VoteButton'
import CommentIcon from '../../shared/assets/icons/comment.svg'
import Card from '../../components/Card'
import FeedbackHeader from '../../components/FeedbackHeader'
import filterCards from '../../utils/filterCards'
import ModalRenderer from '../../components/ModalRenderer'
import InfiniteScroll from 'react-infinite-scroller';
// import TableDropDown from '../../../../components/TableDropDown'
// import Label from '../../../../components/Label'
// import AddFeedbackModal from '../../../../components/AddFeedbackModal'
// import CardsEmptyState from '../../../DashboardView/components/CardsEmptyState'
// // import statusOptions from '../../statusOptions'
// import sortOptions from '../../sortOptions'
// import stateOptions from '../../stateOptions'
// import InfoImg from '../../../../shared/assets/icons/info-circle-solid.svg'
// import { ReactComponent as SortDown } from '../../../../shared/assets/icons/sort-down.svg'
// import { ReactComponent as SortUp } from '../../../../shared/assets/icons/sort-up.svg'
// import { ReactComponent as QuestionIcon } from '../../../../shared/assets/icons/question-circle-solid.svg'
// import sortCards from '../../../../utils/sortCards'

// import { renderRows, renderBulmaRows } from './helpers'
// import { Wrapper, SwitchWrapper } from './style'
import './style.css'

const translations = {
  "en": {
    "button": "Make a suggestion",
    "whitelabel": "Powered by",
    "sort": {
      "Latest": "Latest",
      "Top": "Top",
      "Oldest": "Oldest"
    },
    "search": "Search"
  },
  "fr": {
    "button": "Faire une suggestion",
    "whitelabel": "Optimisé par",
    "sort": {
      "Latest": "Le plus récent",
      "Top": "Top votes",
      "Oldest": "Le plus ancien"
    },
    "search": "Chercher..."
  },
  "pt": {
    "button": "Faça uma sugestão",
    "whitelabel": "Distribuído por",
    "sort": {
      "Latest": "Recentes",
      "Top": "Mais votados",
      "Oldest": "Antigo",
    },
    "search": "Buscar"
  },
}


export default class PublicFeedbackView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      settingsLoaded: false,
      limit: 20,
      skip: 0,
      hasMore: true
    }
  }

  componentWillMount() {
    document.body.classList.add('public-view-page');
  } 

  componentWillReceiveProps(nextProps) {
    const { organization } = nextProps
    const { getWidgetSettings, loadCards, ssoLoginUser } = this.props
    const { limit, skip } = this.state

    if (organization !== this.props.organization) {
      getWidgetSettings({
        cb: (err, res = {}) => {
          const { data = {} } = res
  
          const { modalBaseColor, modalSecondaryColor, modalNonPublic,
            whiteLabel, locale } = data
  
          this.setState({
            baseColor: modalBaseColor,
            darkColor: modalSecondaryColor || modalBaseColor,
            nonPublic: modalNonPublic,
            whiteLabel: whiteLabel,
            locale: locale,
            settingsLoaded: true
          })
        }
      })
      loadCards({limit, skip})

      const storage = localStorage.getItem('act_public_storage')
      
      if (storage) {
        const storageData = JSON.parse(storage) || {}
        const storageUser = storageData.user || {}
  
        if (storageUser.email) {
          ssoLoginUser({ data: storageUser })
        }
      } 
    }
  }

  componentDidMount() {
    const { getOrg, match, ssoLoginUser } = this.props
    const { params = {} } = match

    getOrg({slug: params.id, 
      cb: (err, res = {}) => {
        if (res) {
          const storage = localStorage.getItem('act_public_storage')
          
          if (storage) {
            const storageData = JSON.parse(storage) || {}
            const storageUser = storageData.user || {}
      
            if (storageUser.email) {
              ssoLoginUser({ data: storageUser })
            }
          }
        } else {
          this.setState({error: true})
        }
      }
    })    
  }

  changelink = (link) => {
    const { history, location, organization } = this.props

    return history.push(`/v/${organization.slug || 'getacute'}${link}`)
  } 

  roadmap = () => {
    return (
      <>
        <nav class="navbar is-transparent is-acute is-public" role="navigation" aria-label="main navigation">
          <div class="navbar-brand">
            <a class="navbar-item" href="https://getacute.io" target="_blank">
              <img src="/images/acute_icon.png" style={{height: '28', width: 'auto'}} />
            </a>
          </div>

          <a class="navbar-item is-link">
            Feedback
          </a>

          <a class="navbar-item is-link is-active">
            Roadmap
          </a>

          <div class="navbar-end">
            <div class="navbar-item">
              <a class="button is-primary powered">
                <strong>Powered by Acute</strong>
              </a>
            </div>
          </div>
        </nav>
        <nav class="navbar is-transparent is-acute is-public is-second" style={{marginTop: '-30px'}}>
          <div style={{textAlign: 'center'}}>
            <h1 class="header title is-spaced">Acute Roadmap</h1>
          </div>
        </nav>

        <div class="container" style={{marginTop: '1.5rem'}}>
          <div class="columns">
            <div class="column">
              <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1.5rem'}}>
                <div class="status-dot-blue" />
                <span class="title is-5"><strong>Planned</strong></span>
              </div>
              <div class="box">
                <article class="media">
                  <div class="media-content">
                    <div class="content">
                      <nav class="level" style={{marginBottom: '1em'}}>
                        <div>
                          <span class="level-item">
                            <p class="title is-6 is-spaced">
                              <a style={{ color: '#363636' }} href={'/feedback/' + 1}>
                                Feature request
                              </a>
                            </p>
                          </span>
                        </div>
                      </nav>
                      {/* {card.state !== 'req' &&
                        <StateLabel
                          value={card.state}
                        />
                      } */}
                      <p class="subtitle is-6">Body</p>
                    </div>
                  </div>
                </article>
            </div>
            </div>
            <div class="column">
              <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1.5rem'}}>
                <div class="status-dot-yellow" />
                <span class="title is-5"><strong>In Progress</strong></span>
              </div>
              <div class="box">
                <article class="media">
                <div class="media-content">
                  <div class="content">
                    <nav class="level" style={{marginBottom: '1em'}}>
                      <div>
                        <span class="level-item">
                          <p class="title is-6 is-spaced">
                            <a style={{ color: '#363636' }} href={'/feedback/' + 1}>
                              Feature request
                            </a>
                          </p>
                        </span>
                      </div>
                    </nav>
                    <p class="subtitle is-6"></p>
                  </div>
                </div>
              </article>
              </div>
              <div class="box">
                <article class="media">
                <div class="media-content">
                  <div class="content">
                    <nav class="level" style={{marginBottom: '1em'}}>
                      <div>
                        <span class="level-item">
                          <p class="title is-6 is-spaced">
                            <a style={{ color: '#363636' }} href={'/feedback/' + 1}>
                              Feature request
                            </a>
                          </p>
                        </span>
                      </div>
                    </nav>
                    <p class="subtitle is-6">Body</p>
                  </div>
                </div>
              </article>
              </div>
            </div>
            <div class="column">
            <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1.5rem'}}>
                <div class="status-dot-green" />
                <span class="title is-5"><strong>Completed</strong></span>
              </div>
            <div class="box">
            {/* <article class="media">
              <SearchInput
                onSubmit={this.onSearch}
                large
                placeholder="Search feedback"
                withIcon
                noBorder
                //value={this.state.searchValue}
              />
              <div class="add-button">
                Make a suggestion
              </div>
            </article> */}
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <nav class="level" style={{marginBottom: '1em'}}>
                    <div>
                      <span class="level-item">
                        <p class="title is-6 is-spaced">
                          <a style={{ color: '#363636' }} href={'/feedback/' + 1}>
                            Feature request
                          </a>
                        </p>
                      </span>
                    </div>
                  </nav>
                  {/* {card.state !== 'req' &&
                    <StateLabel
                      value={card.state}
                    />
                  } */}
                  <p class="subtitle is-6">Body</p>
                </div>
              </div>
            </article>
            </div>
            <div class="box">
                <article class="media">
                <div class="media-content">
                  <div class="content">
                    <nav class="level" style={{marginBottom: '1em'}}>
                      <div>
                        <span class="level-item">
                          <p class="title is-6 is-spaced">
                            <a style={{ color: '#363636' }} href={'/feedback/' + 1}>
                              Feature request
                            </a>
                          </p>
                        </span>
                      </div>
                    </nav>
                    <p class="subtitle is-6"></p>
                  </div>
                </div>
              </article>
              </div>
              <div class="box">
                <article class="media">
                <div class="media-content">
                  <div class="content">
                    <nav class="level" style={{marginBottom: '1em'}}>
                      <div>
                        <span class="level-item">
                          <p class="title is-6 is-spaced">
                            <a style={{ color: '#363636' }} href={'/feedback/' + 1}>
                              Feature request
                            </a>
                          </p>
                        </span>
                      </div>
                    </nav>
                    <p class="subtitle is-6"></p>
                  </div>
                </div>
              </article>
              </div>
                  
            </div>
          </div>
        </div>
      </>
    )
  }

  onSearchChange = (e) => {
    const { value } = e.target

    this.setState(() => ({ searchValue: value }))
  }

  loadMoreFeedback = (page) => {
    
    this.setState({
      isloadingMoreFeedback: true
    })

    const { loadMoreCards } = this.props
    let { limit, skip } = this.state

    skip = skip + limit

    loadMoreCards({skip, limit, 
      cb: (err, res = {}) => {
        const { data = {} } = res

        this.setState({
          skip: skip,
          hasMore: data.length > 0 ? true : false,
          isloadingMoreFeedback: false
        })
        }
      })
  }

  content = () => {
    const {  cards = [], loading = false, organization = {} } = this.props
    const { settingsLoaded, baseColor, darkColor, whiteLabel, searchValue, locale,
      isloadingMoreFeedback, hasMore } = this.state

    let buttonLabel = translations["en"]["button"]
    let brandingLabel = translations["en"]["whitelabel"]
    let searchPlaceholer = translations["en"]["search"]

    if (translations[locale]) {
      buttonLabel = translations[locale]["button"]
      brandingLabel = translations[locale]["whitelabel"]
      searchPlaceholer = translations[locale]["search"]
    }

    const filteredCards = filterCards(cards, searchValue)

    if (this.props.location.pathname.includes("roadmap")) {
      return (
        this.roadmap()
      )
    }
    
    return (
      <>
        <ModalRenderer darkColor={darkColor} />
        <FeedbackHeader 
          baseColor={baseColor} 
          darkColor={darkColor} 
          whiteLabel={whiteLabel}
          onSearch={this.onSearchChange} 
          searchValue={searchValue}
          locale={locale}
        />

        {
          loading ? <div style={{ position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center' }}>
              <Spinner />
            </div>
          :  <div class="container" style={{maxWidth: '730px', marginTop: '1.5rem', paddingBottom: '3rem', paddingLeft: '1.5rem', paddingRight: '1.5rem'}}>
        <div style={{
              maxHeight: '72vh',
              overflow: 'auto',
              marginTop: '1rem',
              paddingRight: '1rem'
            }}
            ref={(ref) => this.scrollParentRef = ref}>
            <InfiniteScroll
            pageStart={0}
            loadMore={isloadingMoreFeedback ? null : this.loadMoreFeedback}
            hasMore={hasMore}
            loader={<Spinner />}
            useWindow={false}
            getScrollParent={() => this.scrollParentRef}
          >
            { filteredCards.map(card => <Card card={card} darkColor={darkColor} />)}
          </InfiniteScroll></div>
          <div class="app-footer">
            <p class="branding">
              <a href={`https://getacute.io/acute-link?utm_source=feedback_page&utm_medium=powered&utm_campaign=${organization.name}&company=${organization.name}`} target="_new">
                {brandingLabel} <span style={{textDecoration: 'underline'}}>Acute</span>
              </a>
            </p>
          </div>
        </div>
        }
      </>
    )
  }

  render() {
    const { cards = [] } = this.props
    const { settingsLoaded } = this.state

    if (settingsLoaded === false) { 
      return (
        <></>
      )
    } else {
      return this.content()
    }
  }
}