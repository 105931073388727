import React from 'react'
import { Icon } from '@blueprintjs/core'

import { Wrapper, Container, Input, SearchIcon } from './style'

export default class SearchInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value || '',
      isOpen: props.isOpen || false,
      typingTimeout: 0,
      focused: false
    }
  }

  onChange = (e) => {
    const { value } = e.target
    const { onSubmit } = this.props
    const { typingTimeout } = this.state

    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }

    this.setState({
      value: value,
      focused: true,
      typingTimeout: setTimeout(function () {
        onSubmit(value)
      }, 300)
    });
  }

  componentWillReceiveProps(nextProps) {
    const { value, isOpen } = nextProps

    if (value !== undefined && this.state.value !== value) {
      this.setState({
        value: value
      })
    }

    if (this.state.isOpen !== isOpen) {
      this.setState({
        isOpen: isOpen
      })
    }
  }

  onKeyPress = (e) => {
    const { onSubmit } = this.props
    const { value } = this.state

    if (e.key === 'Enter') {
      onSubmit(value)
    }
  }

  onClick = () => {
    const { value } = this.state
    const { onSubmit } = this.props

    this.setState({
      value: '',
      isOpen: false,
      focused: true
    })

    //onSubmit(value)
  }

  open = () => {
    const { onClick } = this.props

    this.setState({ 
      isOpen: true,
      focused: true
    })

    if (onClick) onClick()
  }

  close = () => {
    const { onCancel } = this.props

    this.setState({
      isOpen: false,
      value: ''
    })
    if (onCancel) onCancel()
  }

  handleBlur = () => {
    const { onBlur } = this.props

    this.setState({ focused: false })

    if (onBlur) onBlur()
  }

  render() {
    const { large, placeholder, withIcon, border, noBorder, withBackground } = this.props
    const { value = '', focused } = this.state

    return (
      <Wrapper>
        <Container
          border={border}
          focused={focused}
          noBorder={noBorder}
          withBackground={withBackground}
        >
          { withIcon &&
            <SearchIcon 
              icon='search'
              iconSize={14}
            />
          }
          <Input
            value={value}
            onChange={this.onChange}
            placeholder={placeholder}
            onClick={this.open}
            ref={(input) => this.input = input}
            large={large}
            onKeyPress={this.onKeyPress}
            onBlur={this.handleBlur}
            focused={focused}
            noBorder={noBorder}
          />
        </Container>
      </Wrapper>
    )
  }
}